// @ts-nocheck
import {Column} from 'react-table'
import {SupportInfoCell,SupporStatusCell,SupporpriorityCell} from './SupportInfoCell'
import {SupportActionsCell} from './SupportActionsCell'

import {SupportCustomHeader} from './SupportCustomHeader'
import {ListClinic} from '../../core/_models'

const clinicColumns: ReadonlyArray<Column<ListClinic>> = [
  {
    Header: (props) => <SupportCustomHeader tableProps={props} title="#" className='' />,
    accessor: 'serialNo',
     
  },
  {
    Header: (props) => (
      <SupportCustomHeader tableProps={props} title='Worker Name' className='min-w-125px' />
    ),
    accessor: 'fromName',
    
  },
  {
    Header: (props) => (
      <SupportCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => <SupportInfoCell ListSup={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SupportCustomHeader tableProps={props} title='Subject' className='min-w-125px' />
    ),
    accessor: 'subject',
  },
  
  {
    Header: (props) => (
      <SupportCustomHeader tableProps={props} title='Priority' className='min-w-125px' />
    ),
    accessor: 'supportpriority.priorityName',
    Cell: ({...props}) => <SupporpriorityCell ListSup={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SupportCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'supportstatus.statusName',
    Cell: ({...props}) => <SupporStatusCell ListSup={props.data[props.row.index]} />,
  },
  
  {
  Header: (props) => (
    <SupportCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' /> 
  ),
  id: 'actions',
  Cell: ({...props}) => <SupportActionsCell id={props.data[props.row.index].id}  clinic={props.data[props.row.index]}  />,
  },
]

export {clinicColumns}
