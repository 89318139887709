import {useEffect, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {useDownloadExcel} from 'react-export-table-to-excel'
import {getNonPanelReport} from '../nonpanel-claim-list/core/_requests'
const ReportContent = () => {
  const tableRef = useRef(null)

  const [report, setReport] = useState<any>([])

  const [dateRange, setDateRange] = useState([null, null])

  const [OCDate, setOCDate] = useState('')
  const [startDate, endDate] = dateRange
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)

  const clinicReport = () => {
    setLoading(true)

    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    
    getNonPanelReport(Daterange).then((resRep) => {
      setLoading(false)
      
      setReport(resRep.Data)
      if (resRep?.Data.length > 0) {
        setbtnExcelDisable(false)
        setbtnClearDisable(false)
      } else {
        setbtnExcelDisable(true)
        setbtnClearDisable(true)
      }
    })
    setOCDate(Daterange)
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()
  const clearReport = () => {
    setReport([])

    setDateRange([null, null])
    setOCDate('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  //

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `NonPanelClaims_Report_${fileName}`,
    sheet: 'Users',
  })
  

  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Nonpanel Claimform Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={clinicReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}

            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-120px' colSpan={11}>
                    VISIT DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={11}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'>NO</th>
                  <th className='min-w-140px'>NAME</th>
                  <th className='min-w-140px'>NRIC/PASSPORT</th>
                  <th className='min-w-140px'>WBS CODE</th>
                  <th className='min-w-100px'> CLINIC </th>
                  <th className='min-w-140px'>AGENCY</th>
                  <th className='min-w-100px'>VISIT DATE </th>
                  <th className='min-w-100px'>REASON</th>
                  <th className='min-w-100px'>AMOUNT (RM)</th>
                  <th className='min-w-100px'>APPROVAL STATUS</th>
                  <th className='min-w-100px'>PRESCRIPTION</th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={11} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report?.map((item: any, i: any) => {
                    // 
                    return (
                      <tr key={i}>
                        <td> {i + 1}</td>
                        <td> {item?.employeeName}</td>
                        <td> {item?.nricPassport}</td>
                        <td> {item?.wbsCode}</td>
                        <td> {item?.clinicName} </td>
                        <td> {item?.CompanyName} </td>
                        <td>
                          {' '}
                          {new Date(item.visitDate).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td> {item?.visitReason} </td>
                        <td> {item.amount} </td>
                        <td> {item?.approvalStatus === 1 ? 'Approved' : 'Pending'} </td>
                        <td> {item?.prescription} </td>
                      </tr>
                    )
                  })}
                  {report.length > 0 ? (
                    <tr>
                      <td colSpan={5}> </td>
                      <th> TOTAL </th>
                      <th>
                        {' '}
                        {report?.reduce((a: any, v: any) => (a = a + parseInt(v.amount)), 0)}
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={11} style={{textAlign: 'center'}}>
                        {' '}
                        No Data{' '}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ReportContent}
