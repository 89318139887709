import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Company, CompanyQueryResponse, initialCompany} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const COMPANY_URL = `${API_URL}/company`
const RA_URL = `${API_URL}/roleAccess`
const GET_COMPANY_LIST = `${API_URL}/company/query`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
var AuthVerify=getToken.call(this);

const getCompanies = (query: string): Promise<CompanyQueryResponse> => {
  return axios
    .get(`${GET_COMPANY_LIST}?${query}`,getToken())
    .then((d: AxiosResponse<CompanyQueryResponse>) => {
    //  
      return d.data
    })
} 
 

const getCompanyById = (id:any) => {
  return axios
    .get(`${COMPANY_URL}/${id}`,getToken()) 
    .then((response) =>{
    return response.data;
    }
   )
    
}
const createCompany = (company: Company): Promise<Company | undefined> => {
  return axios
    .post(COMPANY_URL, company,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}
const updatecontrolsClient = (data:any,userId:any)=> {
  return axios
    .post(`${COMPANY_URL}/updateMailSend/${userId}`, data,getToken())
    .then((response) => {
      
      return response.data
     })
   
}

const updateCompany = (company: Company,id:any): Promise<Company | undefined> => {
  return axios
    .put(`${COMPANY_URL}/${id}`, company,getToken())
    .then((response) => {
      // 
       return response.data
      })
}
 
const deleteCompanyById = (id:any) => {
  return axios
    .post(`${COMPANY_URL}/${id}`,{},getToken()) 
    .then((response) =>{
      
      //
    return response.data;
    
    }
   )
}
const deleteSelectedCompany = (companyIds: Array<ID>): Promise<void> => {
  const requests = companyIds.map((id) => axios.delete(`${COMPANY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getCity = async () => {
  const res = await axios.get(`${COMPANY_URL}/list/city`, getToken());
  //
  return res;
}
const getState = async (city:any) => {
  const res = await axios.get(`${COMPANY_URL}/state/${city}`, getToken());
  //
  return res;
}
const getCountry = async (): Promise<CompanyQueryResponse> => {
  const res = await axios.get(`${COUNTRY_URL}`, getToken());
  //
  return res;
}
const getClientPaymentCount = async (id:any)=> {
  const res = await axios.get(`${COMPANY_URL}/viewClient/transactionCount/${id}`, getToken());
  //
  return res;
}
const getCRUD = (roleId: any,moduleId:any)=> {
  return axios
    .post(`${RA_URL}/getModuleWise/CRUD`,{roleId,moduleId},getToken())
    .then((response) => {return response})
   
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${API_URL}/company/status/activateDeactivate/${id}/${status}`, getToken())
    .then((response) => {

  //  
    return response.data;

    }
    )
}
export {
  getCompanies,getCity,
  deleteCompanyById,getState,
  deleteSelectedCompany,
  getCompanyById,
  createCompany,updatecontrolsClient,getCRUD,updateActiveDeactive,
  updateCompany,getCountry,getClientPaymentCount
}
