/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {StatisticsWidget5,StatisticsWidget3,EngageWidget11} from '../../../_metronic/partials/widgets'
import {Widget} from './Widget'
import {SOBTable} from './SOBTable'
import { Link } from 'react-router-dom'
import { widgetCountSOB } from '../core/_request'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SOBDashboardWrapper: FC = () => {
  const intl = useIntl()
  const [count, setCount] = useState<any>([]);
  useEffect(() => {
    widgetCountSOB().then((res: any) => {      
    //  debugger
  
      setCount(res.data.data)   
    });
  }, [])
  const {currentUser}=useAuth();
  const [sobaccess, setsobaccess] = useState<any>([]);
  const [subsobaccess, setsubsobaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,16).then((res: any) => {
      var crudres=res.data?.Data[0];
      setsobaccess(crudres);
  
    })
  }, [])
  useEffect(() => {
    getCRUD(currentUser?.role,17).then((res: any) => {
      var crudres=res.data?.Data[0];
      setsubsobaccess(crudres);
  
    })
  }, [])
  return (
    <>
      <div className='card-header  row  '> 
      <div className='border-0 col-xl-9 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Plan Overview</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Plan Overview</span>
      
      </div>
      <div className="row mb-5  col-xl-3 col-12 col-sm-12 col-md-12 px-0">
     
      <div
          className='px-0 col-xl-12 col-12 col-sm-12 col-md-12 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all benifits'
        >
        {sobaccess?.list===1 ? (
           <Link  style={{float: "right",background: '#9E2A8D'}}
           to='/benefits/list'
           className='btn text-white '
      
           // data-bs-toggle='modal'
           // data-bs-target='#kt_modal_invite_friends'
         >
           <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
           Manage Benifits
           </Link>
        ):("")} 
        </div>
        
      </div>
      </div>
      <div className='row g-5 g-xl-8'>
     
      <div className='col-xl-3'> 
      {sobaccess?.list===1 ? (<Link to="/benefits">
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color=''
            iconColor='primary'
            title={count?.sobCount}
            description='Total  Benifits'
          />
         </Link>):(<StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color=''
            iconColor='primary'
            title={count?.sobCount}
            description='Total  Benifits'
          />)}
        </div>
       
        <div className='col-xl-3'> {subsobaccess?.list===1 ? ( <Link to="/planInfo">
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color=''
            iconColor='primary'
            title={count?.subsobCount}
            description='Total plans'
          />
        </Link>):( <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color=''
            iconColor='primary'
            title={count?.subsobCount}
            description='Total plans'
          />)}
        </div>
        <div className='col-xl-6'>
          <Widget className="card-xl-stretch mb-xl-8" />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      {/* end::Row */}
      <div className='col-xl-12'>
          <SOBTable className='card-xl-stretch mb-5 mb-xl-8'/>
      </div>
    </>
  )
}

export { SOBDashboardWrapper }
