import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../../_metronic/helpers'
import { List, ClinicPaymentQueryResponse, initialValue } from './_models'
import { getToken } from '../../../../../auth/core/AuthHelpers'
import { useAuth } from '../../../../../auth'

const API_URL = process.env.REACT_APP_API_URLS
const MAIN_URL = `${API_URL}/clientInvoice`
var AuthVerify = getToken.call(this);



const getClientInvoice = (query: string,clientId:any): Promise<ClinicPaymentQueryResponse> => {
  return axios
    .post(`${MAIN_URL}/getdata/query/?${query}`,{clientId} ,getToken())
    .then((d: AxiosResponse<ClinicPaymentQueryResponse>) => {
     
      return d.data
    })
}
const getClientInvoiceById = (id:any) => {
  return axios
    .get(`${MAIN_URL}/viewClientInvoice/${id}`,getToken()) 
    .then((response) =>{
    
      return response.data;
    }
   )
}
const addClientInvoice = (data: FormData,clinicID:any) => {
 // 
  return axios
  .post(`${MAIN_URL}/addClientInvoice/${clinicID}`, data,getToken())
  .then((response) => {
   //
    return response.data
   })
   
}
const updateClientFile=(data: FormData,id:any)=>{
  
  
  return axios
  .put(`${MAIN_URL}/UpdateClientPaid/${id}`, data,getToken())
  .then((response) => {
   
    return response.data
   })
}
export {
  getClientInvoice,addClientInvoice,getClientInvoiceById,updateClientFile
}
