/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { StatisticsWidget1 } from '../../../_metronic/partials/widgets'
import { Table } from './Table'
import { Widget } from './Widget'
import { widgetCount} from '../core/_request'
 
import React , { useEffect, useState }from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { ClaimTable } from './ClaimTable'
import { useAuth } from '../../modules/auth'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { StatisticsWidget3 } from './StatisticsWidget3'


const ClinicDashboardWrapper: FC = () => {
  //Current Date
  const current = new Date();
  const dateCurrent = new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
  ////Current Date
  const intl = useIntl()
  //Data Set
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
  const [claimcount, setClaimCount] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }

  useEffect(() => {
    widgetCount().then((res: any) => {      
    setCount(res.data.data)   
    });
  }, [])
 
  const {currentUser}=useAuth();
  const [clinicaccess, setclinicaccess] = useState<any>([]);
  const [claimcaccess, setclaimcaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,39).then((res: any) => {
      var crudres=res.data?.Data[0];
      setclinicaccess(crudres);

    })
  }, [])
  
  useEffect(() => {
    getCRUD(currentUser?.role,6).then((res: any) => {
      var crudres=res.data?.Data[0];
      setclaimcaccess(crudres);

    })
  }, [])
  return (
    <>
<div className='card-header row' style={{marginBottom:'4%'}}>
  <div className='col-lg-6 col-md-6 col-sm-12 mb-sm-5' >
    <h3 className='card-title align-items-start flex-column'>
      <span className='card-label fw-bold fs-3 mb-1'>Clinic Overview</span>
    </h3>
    <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
    <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
    <span className='text-muted mt-1 fw-semibold fs-7'>Clinic Overview</span>
  </div>

  <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-wrap justify-content-end ">
    {clinicaccess?.add === 1 && (
      <div className='card-toolbar' >
        <Link to='/clinics/add'  className='btn btn-sm text-white mb-2' style={{backgroundColor:'#9E2A8D'}}>
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3 text-white' />
          Add Clinic
        </Link>
      </div>
    )}

    {claimcaccess?.list === 1 && (
      <div className='card-toolbar' >
        <Link to='/claim-form' className='btn btn-sm text-white ms-2 mb-2' style={{backgroundColor:'#9E2A8D'}}>
          <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3 text-white' />
          Claim Forms
        </Link>
      </div>
    )}

    {claimcaccess?.list === 1 && (
      <div className='card-toolbar' >
        <Link to='/clinic-category' className='btn btn-sm text-white ms-2 mb-2' style={{backgroundColor:'#9E2A8D'}}>
          <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3 text-white' />
          Add Category
        </Link>
      </div>
    )}
  </div>
</div>


      
      <>

  {/* begin::Row */}
  <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget1
          className='card-xl-stretch mb-xl-8'
          image='abstract-4.svg'
          title='Welcome to Clinics'
          time={"Date: "+dateCurrent}
          description='Manage and configure clinics based<br/>settings here'
        />
      </div>
      <div className='col-xl-8'>

        <Widget className="card-xl-stretch mb-xl-8" />

      </div>

    </div>
     {/* end::Row */}


    {/* begin::Row */}
    
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='Total Visits'
          description='Total number of visits to panel clinics'
          change={count?.visitorsCount}
        />
      </div>
      
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='danger'
          title='Total Clinics'
          description='Total number of active panel clinics'
          change={count?.totalClinicsCount}
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Claim Forms'
          description='Total number of claim form recieved'
          change={count?.totalClaimformCount}
        />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Payments'
          description='Estimatated total of payments made'
          change={count?.clinicPaymentCount}
        />
      </div>
    </div>
    {/* end::Row */}
  
  <div className='col-xl-12'>
      <ClaimTable
        className='card-xl-stretch mb-5 mb-xl-8'
       />
    </div>
    {/* end::Row */}
    <div className='col-xl-12'>
      <Table
        className='card-xl-stretch mb-5 mb-xl-8'
       />
    </div>
 
  </>
    </>
  )
}

export { ClinicDashboardWrapper }
