import React from 'react';

type Props = {
  className: string;
  cardColorTop: string;
  cardColorBottom: string;
  svgIcon: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
};

const StatisticsWidget: React.FC<Props> = ({
  className,
  cardColorTop,
  cardColorBottom,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
 

  const topRowStyle: React.CSSProperties = {
    backgroundColor: cardColorTop,
    padding: '20px',
    color: 'white',
  };

  const bottomRowStyle: React.CSSProperties = {
    backgroundColor: cardColorBottom,
    padding: '20px',
    color: 'white',
  };

  return (
    <a
      href='#'
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{ margin: '0', padding: '0' }}
    >
      <div className='card-body' style={{ height: '100%', width: '100%', margin: '0', padding: '0' }}>
        <div style={topRowStyle}>
          <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>
            <h4 style={{ color: '#FFF' }}>{title}</h4>
          </div>
        </div>
        <div style={bottomRowStyle}>
          <div className={`fw-semibold text-white`}>
            <span>{description}</span>
            <p>Payment Received</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export { StatisticsWidget };
