/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
   
  TablesWidget10,
 
  
} from '../../../_metronic/partials/widgets'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { useAuth } from '../../modules/auth'
import { vistGraph, widgetCount } from '../core/_request'
import { Widget} from '../dashboard/Widget'
import { Widget1} from '../dashboard/Widget1'
import { ClaimformGraph } from './ClaimformGraph'
import { PaymentReceiveGraph } from './PaymentReceiveGraph'
import { PaymentSentGraph } from './PaymentSentGraph'
import { StatisticsWidget5 } from './StatisticsWidget5'
import { VisitsGraph } from './VisitsGraph'

const DashboardWrapper: FC = () => {
  const [count, setCount] = useState<any>([]);

  useEffect(() => {
    widgetCount().then((res: any) => { 
           
    setCount(res.data.data)   
    });
  }, [])

  
 
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 card-strech'
            svgIcon='/media/icons/duotune/finance/fin006.svg'
            color=''
            iconColor='primary'
            title={count?.totalemployeeCount}
           description='Total Workers'
           img={''}   
           
          />
      </div>
      
      <div className='col-xl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 card-strech'
            svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
            color=''
            iconColor='primary'
            title={count?.totalClientsCount}
           description='Total Agency'
           img={''}   
           
          />
      </div>

      <div className='col-xl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/medicine/med008.svg'
            color=''
            iconColor='primary'
            title={count?.totalClinicsCount}
            description='Total Clinic'
             img={''}        
           
          />
      </div>
      <div className='col-xl-3'>
      <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm011.svg'
            color=''
            iconColor='primary'
            title={count?.totalClaimformCount}
           description='Total Claimform'
           img={''}
           
          />
      </div>
    </div>
        
      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
      <Widget className='h-md-50 mb-5 mb-xl-10'  /> 
     <Widget1 className='h-md-50 mb-5 mb-xl-10' /> 
      </div> */}
      
      {/* end::Col */}
   
    {/* end::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
               <StatisticsWidget5
             className=' card-xl-stretch mb-5 mb-xl-8'
             svgIcon='/media/icons/duotune/communication/com007.svg'
             color=''
             iconColor='primary'
             title={count?.supportCount}
            description='Total Support'
            img={''}
        />
      </div>
      
      <div className='col-xl-3'>
      <StatisticsWidget5
             className='card-xl-stretch mb-5 mb-xl-8'
             svgIcon='/media/icons/duotune/finance/fin008.svg'
             color=''
             iconColor='primary'
             title={count?.paymentsent} 
            description='Total Payment Sent'
            img={''}
        />
      </div>

      <div className='col-xl-3'>
      <StatisticsWidget5
             className=' card-xl-stretch mb-5 mb-xl-8'
             svgIcon='/media/icons/duotune/general/gen005.svg'
             color=''
             iconColor='primary'
             title={count?.invoicesent}
            description='Total Invoice Sent'
            img={''}
        />
      </div>
      <div className='col-xl-3'>
      <StatisticsWidget5
             className='card-xl-stretch mb-5 mb-xl-8'
             svgIcon='/media/icons/duotune/finance/fin002.svg'
             color=''
             iconColor='primary'
             title={count?.paymentReceive}
            description='Total Payment Received'
            img={''}
        />
      </div>
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
    
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-6'>
        <VisitsGraph className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-6'>
        <PaymentSentGraph className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-6'>
        <PaymentReceiveGraph className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-6'>
        <ClaimformGraph className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
    
    

    
  </>
 
    
  )
}

export {DashboardWrapper}
