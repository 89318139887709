// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell,ClientEmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'

import {EmployeeCustomHeader} from './EmployeeCustomHeader'
 
import {Employee} from '../../core/_models'

const employeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => <EmployeeCustomHeader tableProps={props} title='##' className='min-w-50px' />,
    accessor: 'serialNo',
  },
  
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
    Cell: ({...props}) => <EmployeeInfoCell clinic={props.data[props.row.index]} />,
  },
  
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Role' className='min-w-125px' />
    ),
    accessor: 'role.roleName',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='UserId' className='min-w-125px' />
    ),
    accessor: 'userId',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'username',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell id={props.data[props.row.index].id} employee={props.data[props.row.index]}/>,
  }, 
]

export {employeeColumns}
