/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import BannerPage from './banner/BannerPage'
import PlanPage from './plan/PlanPage'
 
 
const MobileSettingPage: FC = () => {
 return (
    <>
 <BannerPage/>
 <PlanPage/>
  </>
 )
}

export {MobileSettingPage}
