import {Route, Routes, Outlet, Navigate,Link, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ClinicListWrapper} from './client-support-list/ClinicList'
 
import {AddClinicSupport} from './client-support-form/index'
import { ViewClinicSupport } from './client-support-form/ViewClientupport'
import { EditBasicDetailsWrapper } from './client-support-form/EditBasicDetailsWrapper'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
const clinicBreadcrumbs: Array<PageLink> = [
  {
    title: 'Agency Support',
    path: '/portal-agency-support',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClinicsupportPage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>Agency Support</PageTitle>
               
              <ClinicListWrapper />
            </>
          }
        />
        <Route
          path='/add'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>Add Support</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <AddClinicSupport />
            </>
          }
        />
        <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>View Support</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <ViewClinicSupport />
            </>
          }
        />
          <Route
          path='/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>Edit Support</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <EditBasicDetailsWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default ClinicsupportPage
