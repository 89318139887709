import {useListView} from '../../core/ListViewProvider'
import {InsuranceHolderListToolbar} from './ClientInvoiceToolbar'
import {InsuranceHolderListGrouping} from './ClientInvoiceGrouping'
import {InsuranceHolderListSearchComponent} from './ClientInvoiceSearchComponent'

const ClientInvoiceHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <InsuranceHolderListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <InsuranceHolderListGrouping /> : <InsuranceHolderListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ClientInvoiceHeader}
