import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClientReportWrapper} from './clint-report-list/ClientReportWrapper'

import {KTCard} from '../../../../_metronic/helpers'
import CompanyReportOverview from './CompanyReport/CompanyReportOverview'

import {ClaimListWrapper} from '../individual-report-panel/claim-list/ClaimformList'
import {NonClaimListWrapper} from '../individual-report-nonpanel/nonpanel-claim-list/ClaimformList'

const creportBreadcrumbs: Array<PageLink> = [
  {
    title: 'Credit Report',
    path: '/creditReport',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CreditReportPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={creportBreadcrumbs}>Credit Report </PageTitle>
              <ClientReportWrapper />
            </>
          }
        />
        <Route
          path='/NewReport/View/:id'
          element={
            <>
              <PageTitle breadcrumbs={creportBreadcrumbs}>Credit Report </PageTitle>
              <CompanyReportOverview />
            </>
          }
        />
        <Route
          path='/NewReport/panelvisits/:id'
          element={
            <>
              <PageTitle breadcrumbs={creportBreadcrumbs}>Credit Report </PageTitle>
              <ClaimListWrapper />
            </>
          }
        />
        <Route
          path='/NewReport/nonpanelVisits/:id'
          element={
            <>
              <PageTitle breadcrumbs={creportBreadcrumbs}>Credit Report </PageTitle>
              <NonClaimListWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default CreditReportPage
