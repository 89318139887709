import React,{useContext, useEffect, useRef, useState} from 'react'
import {AddCharges, getClaimListToAddCharges, getCompany } from '../_requests' 
import "react-datepicker/dist/react-datepicker.css";
 import './inputNumber.css'

import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../../../../../_metronic/layout/components/alert-context';
const ClientReportContent = () => {
  const tableRef = useRef(null);
  const { showAlert } = useContext(AlertContext);
  const [com, setCom] = useState<any>([])
  const [comValid, setcomValid] = useState('')
  const [OCcom, setOCcom] = useState(0)
  const [Clists, setClists] = useState<any>([])
  const [btnDisable, setbtnDisable] = useState(true)
  const [SbtnDisable, setSbtnDisable] = useState(true)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    getCompany().then((res: any) => {
      setCom(res.data.Data)
    })
  }, [])  
   
  const companyChange=(event:any)=>{
    var index = event.target.value;
    setOCcom(index);
    setcomValid('');
   
  }
 
const clearReport=()=>{
  
  setOCcom(0);
  setSelectedYear(0);
  setSelectedMonth(0);
  setClists([]);
   
}
 const companyReport=()=>{
  setLoading(true);
  if(OCcom!==0){
    getClaimListToAddCharges(OCcom,selectedYear,selectedMonth).then((res: any) => {
     
      setClists(res.data.Data)
      setLoading(false)
    })
   }
   else{
    const Validation="Please Select Company";
    setcomValid(Validation)
    }


   callBtnProperty()
 }
 function handleAmountChange(index:any, amount:any) {
  // Update the amount for the specified row in the data array
  const newData = [...Clists];
  newData[index].otherCharges = amount;
  setClists(newData);
  callBtnProperty()
   
} 
const updateCharges=()=>{
  AddCharges(Clists).then((res: any) => {
    navigate('/claimform-charges', { replace: true })
    showAlert(res?.data?.Message, res?.data?.Code);
  })
}
const callBtnProperty=()=>{
  const hasOtherCharges = Clists.some((item:any) => item.otherCharges != null && item.otherCharges !== '');
  if(hasOtherCharges){
    setbtnDisable(false)
  }
  else{
    setbtnDisable(true)
  }
}

 
  const handleMonthChange = (event:any) => {
    setSelectedMonth(parseInt(event.target.value));
  
    
  }
  const handleYearChange = (event:any) => {
    setSelectedYear(parseInt(event.target.value));
   
   };

  const years = Array.from({ length: 20 }, (_, i) => 2020 + i); // change this to the range of years you want to display
   
 
  return (
    <>
    
      
     <div className='card '>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 pb-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Add Other Charges</span>
        
        </h3>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='row mb-10'>
              <div className='col-lg-4'>
                <label  className=' col-form-label required fw-bold fs-6'> Agency Name</label>
                <select  value={OCcom} className='form-select form-select-solid form-select-lg fw-bold' id="CompanyID" onChange={companyChange}>
                  <option value=''>Select Agency </option>
                  {com?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                  
                </select>
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> {comValid}</div>
                  </div>
               
              </div>
              <div className='col-lg-4'>
                <label  className=' col-form-label required fw-bold fs-6'> Year</label>
                <select  id="year-select" value={selectedYear} onChange={handleYearChange} className='form-select form-select-solid form-select-lg fw-bold'  >
                  <option value='0'>Select Year </option>
                 
                    {years.map((year,i) => (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    ))}
     
                  
                </select>
               </div>
               <div className='col-lg-4'>
                <label  className=' col-form-label required fw-bold fs-6'> Month</label>
                <select  id="month-select" value={selectedMonth} onChange={handleMonthChange} className='form-select form-select-solid form-select-lg fw-bold'  >
                  <option value='0'>Select Month </option>
                 
                  {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i+1}>
                    {new Date(selectedYear,i).toLocaleString("default", { month: "long" })}
                  </option>
                ))}
            
                  
                </select>
               </div>
               
              <div className='row mt-10'>
              <div className='col-lg-2'>
                
              <button
                onClick={companyReport}
                className='btn text-white col-lg-12'
                style={{background: '#9E2A8D'}}
              >
                Search
              </button>
              </div>
              <div className='col-lg-2'>
                
                <button
                  onClick={clearReport}
                  className='btn btn-danger col-lg-12'
                >
                  Clear
                </button>
                </div>
                <div className='col-lg-2'>
                <button
                  onClick={updateCharges}
                  className='btn text-white col-lg-12'
                  style={{background: '#9E2A8D'}}
                   disabled={btnDisable}
                >
                  Update Charges
                </button>
              
                </div>
                </div>
                 
        </div>

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'  ref={tableRef}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted '>
                <th className='min-w-50px'>   NO</th>
                <th className='min-w-140px'>	WORKER NAME</th>
                <th className='min-w-140px'>	WORKER ID</th>
                <th className='min-w-140px'>	PATIENT NAME</th>
                <th className='min-w-140px'>	CLAIM DATE</th>
                <th className='min-w-120px'>	CLINIC NAME</th>
                <th className='min-w-120px'>	AGENCY NAME</th>
                <th className='min-w-120px'>	AMOUNT</th>
                <th className='min-w-120px'>	OTHER CHARGES</th>
              </tr>
            </thead>
            {loading && 
            <tbody><tr >
            <td colSpan={9} style={{textAlign:"center"}}>    Please wait...{' '} </td>
              
            </tr></tbody>
             }  
          {!loading && (
            <tbody className='fw-bold text-muted'>
            {Clists?.map((item: any,i:any) => {
               return(
                 <tr  key={i}>
                <td>{i+1} </td> 
                <td>{item.employeeName}</td>
                <td>{item.employeeId} </td>
                <td>{item.patientName!=null ? (item.patientName) : ('SELF')}</td>
                <td>{ new Date(item.visitDate).toLocaleDateString("en-GB", { day: "numeric",  month: "short",year: "numeric" }) } </td>
                <td>{item.clinic?.clinicName}  </td>
                <td>{item.client?.clientName}</td>
                <td>{item.amount} </td>
                <td><input type="number"  
                 value={item.otherCharges}  onWheel={event => {var blurEve= event.target    as HTMLInputElement; 
                  blurEve.blur();
                }}
                 onChange={(event) => handleAmountChange(i, event.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' /> </td>
               </tr>
              )})} 
             {
              Clists.length===0 ? (
                <tr >
                  <td colSpan={9} style={{textAlign:"center"}}> No Data </td>
                  </tr>
              ):("")
             }   
             </tbody>)}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    
     
    </>
       
       
  )
}

export {ClientReportContent}
