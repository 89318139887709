/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useAuth} from '../../../../../auth'
import {Modal, Button} from 'react-bootstrap'
import {Claim} from '../../core/_models'
import {deleteClaimFormById, getCRUD} from '../../core/_requests'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
type Props = {
  claimform: Claim
}

const ClaimformActionsCell: FC<Props> = ({claimform}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [roleaccess, setroleaccess] = useState<any>([])
  const [editbtnDisable, seteditbtnDisable] = useState(false)
  const [deltbtnDisable, setdeltbtnDisable] = useState(true)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    getCRUD(currentUser?.role, 6).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])

  const viewDetails = () => {
    if (roleaccess?.view === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      navigate('/claim-form/view/' + claimform.primaryId)
    }
  }
  //
  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const PermanentdeleteItem = () => {
    //second parameter 1 is remove permanent sign
    deleteClaimFormById(claimform.primaryId, 1).then((resp) => {
      setShow(false)
      showAlert(resp?.Message, resp?.Code)
      navigate('/claim-form', {replace: true})
    })
  }
  const removedeleteItem = () => {
    //second parameter 2 is remove temporary sign
    deleteClaimFormById(claimform.primaryId, 2).then((resp) => {
      setShow(false)
      showAlert(resp?.Message, resp?.Code)
      navigate('/claim-form', {replace: true})
    })
  }
  return (
    <>
      <ToastContainer />

      {/* Same as */}

      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          onClick={viewDetails}
          className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1  `}
        >
          <KTSVG path='/media/icons/duotune/general/eye.svg' className='svg-icon-3' />
        </a>
        {/* {roleaccess?.remove===1 ? (
                    <a onClick={handleShow}
                    
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>  ) :("")} */}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete this Claimform ?</Modal.Header>
        <Modal.Footer>
          <Button variant='danger' onClick={PermanentdeleteItem}>
            Permanently Delete
          </Button>
          <Button variant='primary' onClick={removedeleteItem}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ClaimformActionsCell}
