import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
 

import React, { useEffect, useState } from 'react'
import { ClientInvoiceFilter } from './ClientInvoiceFilter'
 
interface Event<T = EventTarget> {
  target: T
  // ...
}
const InsuranceHolderListToolbar = () => {
   
  
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const openAddUserModal = () => {
    navigate('/agency-invoice/add')
  }
   
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <CompanyListFilter /> */}
<ClientInvoiceFilter/>
      
      {/* begin::Add user */}
      {/* <button type='button'style={{marginRight:"10px"}}  className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
       Create
      </button> */}
      {/* begin::Upload */}
     
    
    </div>
  )
}

export {InsuranceHolderListToolbar}
