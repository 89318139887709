import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClinicListHeader} from './components/header/ClinicListHeader'
import {ClinicTable} from './table/ClaimformTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import {useContext, useEffect} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const ClinicList = () => {
  const {show, message, type} = useContext(AlertContext)
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <>
      {show && <AlertBox type={type == '1' ? 'success' : 'error'} message={message} />}
      <button
        onClick={goBack}
        className='btn btn-sm btn-info px-6 align-self-center text-nowrap mb-10'
      >
        <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
      </button>
      <KTCard>
        <ClinicListHeader />
        <ClinicTable />
      </KTCard>
    </>
  )
}

const NonClaimListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClinicList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {NonClaimListWrapper}
