import React, {useContext, useEffect, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getCategory, updateCategory} from '../role-list/core/_requests'
import {useAuth} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import {MyContext} from '../role-list/ContextFile'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../role-list/core/QueryResponseProvider'

export interface IProfileDetails {
  category: string
}

export let initialValues: IProfileDetails = {
  category: '',
}
const CategoryDetailsSchema = Yup.object().shape({
  category: Yup.string().required('Category name is required'),
})

const EditCategory: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {state, setState} = useContext(MyContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState(state)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()

  /* useEffect(() => {
 
    getCategory().then((res: any) => {
      
      //setTreatment(res.data.Data);
     // setcCategory(res.data.Data);
    })
  },[]) */
  useEffect(() => {
    
    setData(state)
    formik.setFieldValue('category', state?.name)
    formik.setFieldValue('primaryId', state?.primaryId)
  }, [state])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: CategoryDetailsSchema,
    onSubmit: (values, actions) => {
      actions.resetForm({
        values: initialValues,
      })
      
      setLoading(true)
      setTimeout(() => {
        const data = {name: values.category}
        const id = {}
        updateCategory(state.primaryId, values.category, currentUser?.Id).then((res) => {
          
          showAlert(res?.Message, res?.Code)
          //navigate('/claim-form/list', {replace: true})
          refetch()
        })
        setLoading(false)
      }, 1000)
    },

    enableReinitialize: true,
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Category </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Category Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter category name'
                    id='categoryName'
                    {...formik.getFieldProps('category')}
                  />
                  {formik.touched.category && formik.errors.category && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.category}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Update Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditCategory
