/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useEffect, useState }from 'react'
import { KTSVG,  } from '../../../../_metronic/helpers'
 
import Avatar from 'react-avatar';
import { Link,useNavigate } from 'react-router-dom';
 
import { useAuth } from '../../../modules/auth';
import { recentSupportClinic } from '../../../pages/core/_request';
 

type Props = {
  className: string
}

const ClinicTable: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate() 
  
  
  const viewClinicDetails = (Id:any) => {
    navigate('/clinic-support/view/' + Id)
  }
  const {currentUser}=useAuth();
  const [clinic, setclinic] = useState<any>([]);
   
   useEffect(() => {
    recentSupportClinic().then((res: any) => {
      setclinic(res.data.Data)
      
    });
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Clinics</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>List of recently created supports to clinics</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all data'
        >
        
        <Link 
        to='/clinic-support'
        className='btn btn-sm text-white'
        style={{background: '#9E2A8D'}}
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
        <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3 text-white' />
       View All
        </Link>
        
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
            
            <tr className='fw-bold text-muted'>
              
              <th className='min-w-150px'>Date</th>
             
              <th className='min-w-140px'>	Support Railsed By</th>
              <th className='min-w-140px'>	Subject</th>
              <th className='min-w-120px'>	Priority</th>
              
              <th className='min-w-120px'>	Status</th>
              <th className='min-w-100px text-end'>Actions</th> 
            </tr>
          
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          {clinic.length > 0 ? (clinic?.map((item: any) => {
            return(
              <tr key={item.id}>
              <td>
                {
                 
                  new Date(item?.createdAt).toLocaleDateString("en-GB", {
                   day: "numeric",
                   month: "short",
                   year: "numeric"
                 })
                }
              </td>
              
              <td>
                  {item?.fromName}
               </td>
             
              <td>
                {item?.subject}
              </td>
              <td>
                <span className='badge badge-light-warning'>{item.supportpriority?.priorityName}</span>
                  
                </td>
                
                <td>
                <span className='badge badge-light-success'>{item.supportstatus?.statusName}</span>
                  
                 </td>
              
                 <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <a    onClick={() => viewClinicDetails(item.id)}
                    
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/eye.svg'
                      className='svg-icon-3'
                    />
                  </a>
                   
                   
                </div>
              </td> 
            </tr>
            )
           
              })):(
                <tr>
                <td colSpan={6} style={{textAlign:"center"}}>No support requested</td>
              </tr>
              )}
          </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { ClinicTable }
