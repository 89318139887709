import { string } from 'yup';
import {ID, Response} from '../../../../../../../_metronic/helpers'

export type ClinicSupport = {
  id?:ID; 
  priority:string,
  message:string,
  subject: string,
  Code: string,
  createdAt: string,
  Message: string,
  fromId: any,
  
}


export type ClinicQueryResponse = Response<Array<ClinicSupport>>

export const initialValues: ClinicSupport = {
 
  subject:'',
  message:'',
  priority:'',
  createdAt: '',
  Code: '',
  Message: '',
  fromId: '',
 
   
}
 
export type ListClinic = {
  id?:ID; 
  primaryId?: string,
  subject: string,
  message: string,
  createdAt: string,
  priority: string,  
  fromId: any,
  
}