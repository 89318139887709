/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {adminrecentClailforms} from '../core/_request'
import {listRecentClinics, initialValues} from '../core/_models'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {getCRUD} from '../../modules/apps/companies/company-list/core/_requests'

type Props = {
  className: string
}

const ClaimTable: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const [data, setData] = useState<listRecentClinics>(initialValues)
  const [clinics, setClinics] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentClinics>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    adminrecentClailforms().then((res: any) => {
      setClinics(res.data.Data)
     
    })
  }, [])
  const viewClinicDetails = (Id: any) => {
    navigate('/claim-form/view/' + Id)
  }
  const {currentUser} = useAuth()
  const [claimaccess, setclaimcaccess] = useState<any>([])

  useEffect(() => {
    getCRUD(currentUser?.role, 6).then((res: any) => {
      var crudres = res.data?.Data[0]
      setclaimcaccess(crudres)
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Claimforms</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            List of recently created claimforms
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all data'
        >
          {claimaccess?.list === 1 ? (
            <Link
              to='/claim-form/list'
              className='btn btn-sm text-white'
              style={{background: '#9E2A8D'}}
            >
              <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3 text-white' />
              View All
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-140px'> Agency Name</th>
                <th className='min-w-140px'> Clinic Name</th>
                <th className='min-w-120px'> Worker</th>

                <th className='min-w-120px'> Amount ( RM ) </th>
                {claimaccess?.view === 1 ? <th className='min-w-100px text-end'>Actions</th> : ''}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {clinics?.map((item: any) => {
                return (
                  <tr key={item?.id}>
                    <td>
                      {new Date(item?.visitDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                         
                            {item?.client?.clientName}
                          
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          
                           
                              {item?.clinic?.clinicName}
                            
                         
                        </div>
                      </div>
                    </td>
                    <td>
                      {item.EmployeeName}
                      <br></br>
                      {item.employeeId}
                    </td>

                    <td>{item.amount}</td>

                    {claimaccess?.view === 1 ? (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            onClick={() => viewClinicDetails(item?.id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/eye.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ClaimTable}
