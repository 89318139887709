import { FC, useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { deleteEmployee } from '../../core/_requests'
import { Employee } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import { Modal, Button } from "react-bootstrap";
import { useAuth } from '../../../../../auth'
import { getCRUD } from '../../../../companies/company-list/core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertContext } from '../../../../../../../_metronic/layout/components/alert-context'
type Props = {
  employee: Employee
}

const EmployeeActionsCell: FC<Props> = ({ employee }) => {
  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
   
  const {currentUser}=useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,47).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  const handleShow = () => 
 {
  if(roleaccess?.remove===0){  
    toast.error('You do not have permission to access this page', {
      position: "top-right",
      style: {
        backgroundColor: '#9b2313',
        color:'#c7c7d7',
        fontSize:'14px'
      },
      closeButton: false,
    
    })
  }else{
  setShow(true);
  }
 }
  const viewSubsobDetails = () => 
  {
    if(roleaccess?.view===0){  
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color:'#c7c7d7',
          fontSize:'14px'
        },
        closeButton: false,
      
      })
    }else{
    navigate('/workers/view/' + employee.primaryId)
    }
  }
  const deleteItem = () => deleteEmployee(employee.primaryId).then((res) => 
  {
    showAlert(res?.Message,res?.Code);
    setShow(false);
    navigate('/agency-role-permission')
  });


  return (
    <><ToastContainer/>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a onClick={viewSubsobDetails}
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG
            path='/media/icons/duotune/general/eye.svg'
            className='svg-icon-3'
          />
        </a>

        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{employee.name}"    ?</Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary" onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export { EmployeeActionsCell }
