import React, { useContext, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getRole ,createEmployee} from '../client-assign-employee-list/core/_requests'
import {AddEmployee, initialValues } from '../client-assign-employee-list/core/_models'
import {  Link, useNavigate } from 'react-router-dom';
import '../client-assign-employee-form/employee.css'
import { useAuth } from '../../../auth'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { checkDuplicateEmail } from '../../employee/employee-list/core/_requests'
interface Event<T = EventTarget> {
  target: T
  // ...
}


const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
  .email("Invalid email format")
  .test('domain', 'Invalid domain name', function (value) {
    const domain = value?.split('@')[1];
    if (!domain) {
      return false;
    }
    // Define regular expression for valid domain format
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  })
  .min(3, "Minimum 3 symbols")
  .max(50, "Maximum 50 symbols")
  .required("Email is required"),

  password: Yup.string().required('Password  is required'),
})

const ClinicDetails: React.FC = () => {

  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate();
  const [role, setRole] = useState<any>([]);
  const [Duplicate, setDuplicate] = useState("");
  const [loading, setLoading] = useState(false)
  //get type
  const {currentUser} = useAuth();
   
    const  type=2;
  

  //end get type
  const formik = useFormik<AddEmployee>({
    
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values,actions) => {
      actions.resetForm({
      values: initialValues})
      setLoading(true)
      setTimeout(() => {
      values.type=type;
      values.createdBy=currentUser?.Id;
      values.typeId=currentUser?.primaryID;
        createEmployee(values).then(
          (res)=>{
            showAlert(res?.Message,res?.Code);
            navigate('/agency-role-permission', {replace: true})
          }
        )
        setLoading(false)
      }, 1000)
    },
  })

  useEffect(() => {
    getRole(currentUser?.primaryID).then((res: any) => {
      setRole(res.data.Data)
    });
  }, [])
  
  useEffect(() => {
    try {
      checkDuplicateEmail(formik.values.email).then((response) => {
        if (response?.isDuplicate === true) {
          setDuplicate("Email already exist");
        } else {
          setDuplicate("");
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  }, [formik.values.email]);
  
  return (
    
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>New User</h3>
          </div>
         
        </div>
       
        <div id='kt_account_profile_details'>
          <div className='card-body border-top p-9'>
            {/** Name */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            {/** Role */}
            <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
            <div className='col-lg-8'>
                  <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        {...formik.getFieldProps('roleId')}
                      >
                        <option value=''>Select a Role...</option>
                        {role.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                  </div>
            </div>
            {/** Email */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter email'
                  {...formik.getFieldProps('email')}
                />
                  <span style={{ color: "red" }}>
                    {Duplicate ? Duplicate : ""}
                  </span>
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            
            {/** Password */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>
              <div className='col-lg-8'>
              <input
                  type='password' autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
            {/*  */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn text-white' style={{background: '#9E2A8D'}} disabled={loading || Duplicate != ""}>
                {!loading && 'Save User'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      

    </form>
  )
}

export default ClinicDetails
