import {ID, Response} from '../../../../../../../_metronic/helpers'
export type Banner = {
  id?: ID
  primaryId?: string,
  name: string,
  bannerimage: any,
  formImage:any,
  title: string,
  description: string,
  Code:string,
  Message:string,
 
}

export type RolesQueryResponse = Response<Array<Banner>>

export const initialValues: Banner = {
  name: '',
  bannerimage: '',
  title: '',
  description: '',
  Code:'',
  Message:'',
  formImage:''
  
}

export interface AddBanner {
  Code:string
  Message:string
  id: string
  name: string
  image:any; 
  title: string
  description: string
 }
 export const initialValuesADD: AddBanner = {
  Code:"",
  Message:"",
  id: "",
  name: "",
  image:"",
  title: "",
  description: "",
 }