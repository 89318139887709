import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CategoryListHeader} from './components/header/CategoryListHeader'
import {CategoryTable} from './table/CategoryTable'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import { MyContext } from './ContextFile'
import { useContext } from 'react'
import { ContextProvider } from './ContextProvider'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { AlertBox } from '../../../../../_metronic/layout/components/alert-message'
import EditCategory from '../role-form/EditCategory'
import AddCategory from '../role-form/AddCategory'

const CategoryAdd = () => {
  const { state,setState } = useContext(MyContext);
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
       
      {state.editclicked===true ? (<EditCategory/>):( <AddCategory/>)}
      

      </KTCard>
      
    </>
  )
}
const CategoryList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CategoryListHeader />
        <CategoryTable />
      </KTCard>
       
    </>
  )
}
const CategoryMain = ()=>{
  const { show, message,type } = useContext(AlertContext);
  //
  return(
    <>
  <div className='row'>
  {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}  
 
  <div className='col-lg-6'>
  <CategoryAdd />
  </div>
  <div className='col-lg-6'>
  <CategoryList />
  </div>
  </div>
  </>
  )
}

const RoleListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
      <ContextProvider>
     <CategoryMain/>
     </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RoleListWrapper}
