import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ClientDashboardWrapper} from '../pages/Client Dashboard/ClientDashboardWrapper'
import {ClinicDashboardWrapper} from '../pages/Clinic Dashboard/ClinicDashboardWrapper'
import {ClinicportalWrapper} from '../pages/Clinic-portal/ClinicportalWrapper'
import {ClientPortalWrapper} from '../pages/Client-portal/ClientPortalWrapper'
import {SOBDashboardWrapper} from '../pages/SOB Dashboard/SOBDashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import ClincReportPortalPage from '../modules/apps/clinic-portal/clinic-report/ClincReportPortalPage'
import ClientReportPortalPage from '../modules/apps/client-portal/client-report/ClientReportPortalPage'
import AdminClinicPaymentPage from '../modules/apps/admin-clinic-payment/AdminClinicPaymentPage'
import ClientPortalInvoicePage from '../modules/apps/client-portal/client-invoice/ClientPortalInvoicePage'
import NonPanelClaimformPage from '../modules/apps/nonpanel-claim-form/NonPanelClaimformPage'
import AssignSubSobPage from '../modules/apps/assign-subsob/AssignSubSobPage'
import ClientRoleaccessPage from '../modules/apps/client-portal/role-access-setting/ClientRoleaccessPage'
import ClinicRoleaccessPage from '../modules/apps/clinic-portal/role-access-setting/ClinicRoleaccessPage'
import ClientRolePage from '../modules/apps/client-portal/role/ClientRolePage'
import ClinicRolePage from '../modules/apps/clinic-portal/role/ClinicRolePage'
import {getRolewiseRoutes} from './_requests'
import {SupportWrapper} from '../modules/apps/admin-support/SupportWrapper'
import ClinicsupportPage from '../modules/apps/clinic-portal/clinic-support/ClinicsupportPage'
import AdminsupportPage from '../modules/apps/clinic-support/AdminsupportPage'
import ClientsupportPage from '../modules/apps/client-portal/client-support/ClientsupportPage'
import AdminClientsupportPage from '../modules/apps/client-support/AdminClientsupportPage'
import AdminEmployeeSupportPage from '../modules/apps/employee-support/AdminEmployeeSupportPage'
import EmployeeClientsupportPage from '../modules/apps/client-portal/employee-support/EmployeeClientsupportPage'
import OtherchargesPage from '../modules/apps/claim-form/other-charges/OtherchargesPage'
import ClientAssignEmployeePage from '../modules/apps/client-assign-employee/ClientAssignEmployeePage'
import ClinicAssignEmployeePage from '../modules/apps/clinic-assign-employee/ClinicAssignEmployeePage'
import {ReportClaimListWrapper} from '../modules/apps/nonpanel-claim-form/nonpanel-claim-list/ClaimformList'
import NonPanelExportPage from '../modules/apps/nonpanel-claim-form/NonPanelExportPage'
import {
  getrolewisesidemenu,
  routeRedirectSidemenu,
} from '../modules/apps/role-access-setting/role-setting-list/core/_requests'

import {AccessDeniedWrapper} from '../modules/apps/access-denied/access-denied--list/AccessDeniedWrapper'
import {MobileSettingPage} from '../modules/apps/mobile-settings/MobileSettingPage'
import ClinicCategoryPage from '../modules/apps/clinic-category/ClinicCategoryPage'
import AccessDeniedPage from '../modules/apps/access-denied/AccessDeniedPage'
import CreditReportPage from '../modules/apps/credit-report/CreditReportPage'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [routePermission, setroutePermission] = useState<any>([])
  const currentRole = currentUser?.role
  useEffect(() => {
    routeRedirectSidemenu(currentRole).then((res: any) => {
      const sidemenuitems = res.data.Data
      const modules = sidemenuitems.map((response: {module: any}) => response.module.link)
      setroutePermission(modules)
      setIsLoading(false)
    })
  }, [])

  var too = ''
  if (currentUser?.type == '3' || currentUser?.Type == 'clinic') {
    var too = `/clinic-portal`
  }
  if (currentUser?.type == '1' || currentUser?.Type == 'adminEmployee') {
    var too = `/dashboard`
  }
  if (currentUser?.type == '2' || currentUser?.Type == 'client') {
    var too = `/agency-portal`
  }
  const CompaniesPage = lazy(() => import('../modules/apps/companies/CompaniesPage'))
  const EmployeesPage = lazy(() => import('../modules/apps/employee/EmployeePage'))
  const ClinicsPage = lazy(() => import('../modules/apps/clinics/ClinicsPage'))
  const InsuranceHolderPage = lazy(
    () => import('../modules/apps/insurance-holder/InsuranceholderPage')
  )

  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const RolesPage = lazy(() => import('../modules/apps/role/RolePage'))
  const SobPage = lazy(() => import('../modules/apps/sob/SobPage'))
  const SubsobPage = lazy(() => import('../modules/apps/subsob/SubsobPage'))

  const VerifyEmployee = lazy(() => import('../modules/apps/verify-employee/VerifyEmployee'))
  const ClinicPayment = lazy(() => import('../modules/apps/clinic-payment/ClinicPaymentPage'))
  const ClincReportPage = lazy(() => import('../modules/apps/clinic-report/ClincReportPage'))

  const ReportPage = lazy(() => import('../modules/apps/clinic-report-new/ClincReportPage'))
  const CompanyReportPage = lazy(() => import('../modules/apps/company-report/CompanyReportPage'))
  const ClinicSupportPage = lazy(() => import('../modules/apps/clinic-support/AdminsupportPage'))
  const CliniclaimformPage = lazy(
    () => import('../modules/apps/clinic-claimform/CliniclaimformPage')
  )
  const ClientInvoicePage = lazy(() => import('../modules/apps/client-invoice/ClientInvoicePage'))
  const ClientReportPage = lazy(() => import('../modules/apps/client-report/ClientReportPage'))

  const PasswordsettingPage = lazy(
    () => import('../modules/apps/password-setting/PasswordsettingPage')
  )
  const RoleaccessPage = lazy(() => import('../modules/apps/role-access-setting/RoleaccessPage'))
  const ClinicprofilePage = lazy(() => import('../modules/apps/clinic-profile/ClinicprofilePage'))
  const ClientprofilePage = lazy(() => import('../modules/apps/client-profile/ClientprofilePage'))
  const ClientEmployeesPage = lazy(
    () => import('../modules/apps/client-employees/ClientEmployeesPage')
  )

  const DepartmentPage = lazy(() => import('../modules/apps/department/DepartmentPage'))
  const ClientDepartmentPage = lazy(
    () => import('../modules/apps/client-department/ClientDepartmentPage')
  )
  const TreatementPage = lazy(() => import('../modules/apps/treatement/TreatementPage'))
  const MedicalconditionPage = lazy(
    () => import('../modules/apps/medical-condition/MedicalconditionPage')
  )
  const ClaimformPage = lazy(() => import('../modules/apps/claim-form/ClaimformPage'))
  const PortalClaimformPage = lazy(
    () => import('../modules/apps/clinic-portal-claim-form/PortalClaimformPage')
  )

  const PasswordPage = lazy(() => import('../modules/accounts/PasswordPage'))

  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={too} />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='agency-overview/' element={<ClientDashboardWrapper />} />
          <Route path='clinic-overview/' element={<ClinicDashboardWrapper />} />
          <Route path='clinic-portal/' element={<ClinicportalWrapper />} />
          <Route path='plan-overview/' element={<SOBDashboardWrapper />} />
          <Route
            path='companies/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('companies') ? (
                  <CompaniesPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinics/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinics') ? (
                  <ClinicsPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='worker/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('worker') ? (
                  <InsuranceHolderPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='benefits/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('benefits') ? (
                  <SobPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='planInfo/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('planInfo') ? (
                  <SubsobPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='assign-plan/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('assign-plan') ? (
                  <AssignSubSobPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='department/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('department') ? (
                  <DepartmentPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='billing/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('billing') ? (
                  <TreatementPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='diagnosis/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('diagnosis') ? (
                  <MedicalconditionPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='claim-form/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('claim-form') ? (
                  <ClaimformPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='overviewSupport/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('overviewSupport') ? (
                  <SupportWrapper />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinicPortal-support/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinicPortal-support') ? (
                  <ClinicsupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='portal-claim-form/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('portal-claim-form') ? (
                  <PortalClaimformPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='role-access/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('role-access') ? (
                  <RoleaccessPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='portal-agency-support/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('portal-agency-support') ? (
                  <ClientsupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-role-permission/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-role-permission') ? (
                  <ClientRoleaccessPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-role-permission/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-role-permission') ? (
                  <ClinicRoleaccessPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-support/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-support') ? (
                  <AdminsupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-support/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-support') ? (
                  <AdminClientsupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-category/*'
            element={
              <SuspendedView>
                <ClinicCategoryPage />
              </SuspendedView>
            }
          />
          <Route
            path='workerSupport/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('workerSupport') ? (
                  <AdminEmployeeSupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agencyWorkerSupport/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agencyWorkerSupport') ? (
                  <EmployeeClientsupportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-report/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-report') ? (
                  <ClincReportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='CompanyReportPage/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('CompanyReportPage') ? (
                  <CompanyReportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='creditReport/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('creditReport') ? (
                  <CreditReportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='workerReport/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('workerReport') ? (
                  <ReportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-report/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-report') ? (
                  <ClientReportPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-invoice/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-invoice') ? (
                  <ClientInvoicePage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-worker/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-worker') ? (
                  <ClientEmployeesPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-profile/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-profile') ? (
                  <ClientprofilePage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-portal/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-portal') ? (
                  <ClientPortalWrapper />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='agency-department/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('agency-department') ? (
                  <ClientDepartmentPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-claimform/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-claimform') ? (
                  <CliniclaimformPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-payment/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-payment') ? (
                  <ClinicPayment />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-payments/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-payments') ? (
                  <AdminClinicPaymentPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-verify-employee/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-verify-employee') ? (
                  <VerifyEmployee />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='portal-clinic-report/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('portal-clinic-report') ? (
                  <ClincReportPortalPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='portal-agency-report/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('portal-agency-report') ? (
                  <ClientReportPortalPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='portal-agency-invoice/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('portal-agency-invoice') ? (
                  <ClientPortalInvoicePage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='clinic-profile/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('clinic-profile') ? (
                  <ClinicprofilePage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='nonpanel-claims-form/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('nonpanel-claims-form') ? (
                  <NonPanelClaimformPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='claimform-charges/*'
            element={
              <SuspendedView>
                <OtherchargesPage />
              </SuspendedView>
            }
          />
          <Route
            path='NonpanelReport/*'
            element={
              <SuspendedView>
                <NonPanelExportPage />
              </SuspendedView>
            }
          />
          <Route
            path='profile/*'
            element={
              <SuspendedView>
                <AccountPage />
              </SuspendedView>
            }
          />
          <Route
            path='changePassword/*'
            element={
              <SuspendedView>
                <PasswordPage />
              </SuspendedView>
            }
          />
          <Route
            path='clinic-roles/*'
            element={
              <SuspendedView>
                <ClinicRolePage />
              </SuspendedView>
            }
          />
          <Route
            path='agency-roles/*'
            element={
              <SuspendedView>
                <ClientRolePage />
              </SuspendedView>
            }
          />
          <Route
            path='password-setting/*'
            element={
              <SuspendedView>
                <PasswordsettingPage />
              </SuspendedView>
            }
          />
          <Route
            path='workers/*'
            element={
              <SuspendedView>
                <EmployeesPage />
              </SuspendedView>
            }
          />
          <Route
            path='assign-agency-worker/*'
            element={
              <SuspendedView>
                <ClientAssignEmployeePage />
              </SuspendedView>
            }
          />
          <Route
            path='assign-clinic-worker/*'
            element={
              <SuspendedView>
                <ClinicAssignEmployeePage />
              </SuspendedView>
            }
          />
          <Route
            path='roles/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('roles') ? (
                  <RolesPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />{' '}
          <Route
            path='mobile-settings/*'
            element={
              <SuspendedView>
                {isLoading ? (
                  ''
                ) : routePermission.includes('mobile-settings') ? (
                  <MobileSettingPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='signout/*'
            element={
              <SuspendedView>
                <PasswordsettingPage />
              </SuspendedView>
            }
          />
          {/* <Route
          path='AccessDenied/*'
          element={
            <SuspendedView>
              {!routePermission.includes('AccessDenied')?(<AccessDeniedPage />):("")}
            </SuspendedView>
          }
        /> */}
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
