import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Claim, Fetchclaim, ClaimQueryResponse, AddClaim} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/claimform`
const C_IH_URL = `${API_URL}/insuranceHolder/viewClaimedMember`
const GET_CLAIM_LIST = `${API_URL}/report/company/panelReport/query`
const GET_IH_LIST = `${API_URL}/insuranceHolder/search`
const ADD_CLAIM_URL = `${API_URL}/claimform/addclaim`
const RA_URL = `${API_URL}/roleAccess`
var AuthVerify = getToken.call(this)

const getBalanceDetails = (id: any, catId: any) => {
  return axios
    .get(`${API_URL}claimform/balance/CurrentBalance/${id}/${catId}`, getToken())
    .then((response) => {
      return response
    })
}
const deleteClaimFormById = (id: any,type:any) => {
  return axios.post(`${CLAIM_URL}/delete/hardAndSoft/${id}/${type}`, {}, getToken()).then((response) => {
   // 
    return response.data
  })
}
const getTreatementDetails = (id: any) => {
  return axios.get(`${CLAIM_URL}/claimTreatements/${id}`, getToken()).then((response) => {
   // 
    return response.data
  })
}
const getMCDetails = (id: any) => {
  return axios.get(`${CLAIM_URL}/claimMc/${id}`, getToken()).then((response) => {
   // 
    return response.data
  })
}
const getClaimform = (query: string,id:any): Promise<ClaimQueryResponse> => {
  return axios
    .post(`${GET_CLAIM_LIST}?${query}`,{id}, getToken())
    .then((d: AxiosResponse<ClaimQueryResponse>) => {
     
      return d.data
    })
}
const getClaimformPagination = (data: any) => {
  
  
  return axios
    .get(`${GET_CLAIM_LIST}?page=${data.page}&items_per_page=${data.items_per_page}`, getToken())
    .then((d) => {
     //
      return d.data
    })
}
const getMc = async (): Promise<ClaimQueryResponse> => {
  const res = await axios.get(`${CLAIM_URL}/getMC`, getToken())
  //
  return res
}
const getTreatement = async (): Promise<ClaimQueryResponse> => {
  const res = await axios.get(`${CLAIM_URL}/getTreatment`, getToken())
  return res
}
const createClaimform = (Claim: any,clinicID:any) => {
  //debugger
  Claim.mcdetails = Claim.mcdetails.filter(Boolean)
  let treatmentdetails: any = []
  Claim.treatmentdetails.forEach((element: any) => {
    if (element['cost'] || element['description']) {
      treatmentdetails.push(element)
    }
  })
  Claim.treatmentdetails = treatmentdetails
  return axios
    .post(`${ADD_CLAIM_URL}/${clinicID}`, Claim, getToken())
    
    .then((response) => response.data)
}

const fetchData = async (EMPLOYEEID: string) => {
  const res = await axios.get(`${GET_IH_LIST}/${EMPLOYEEID}`, getToken())
  // 
  return res
}

const getCompanyById = (id: ID): Promise<Claim | undefined> => {
  return axios
    .get(`${CLAIM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Claim>>) => response.data)
    .then((response: Response<Claim>) => response.data)
}



const updateCompany = (clinic: Claim): Promise<Claim | undefined> => {
  return axios
    .post(`${CLAIM_URL}/${clinic.id}`, clinic)
    .then((response: AxiosResponse<Response<Claim>>) => response.data)
    .then((response: Response<Claim>) => response.data)
}

const deleteSelectedClaim = (claimId: Array<ID>) => {
  
  return axios
  .post(`${CLAIM_URL}/deleteBulk`,{claimId},getToken())
}
const approveSelectedClaim = (claimId: Array<ID>) => {
  
  
}
const getClaimformById = (id: any)=> {
  return axios
    .get(`${CLAIM_URL}/${id}`,getToken())
    .then((response) => {return response})
   
}
const getfamilymember = (id: any)=> {
  return axios
    .get(`${C_IH_URL}/${id}`,getToken())
    .then((response) => {return response})
   
}
const fetchapproveclaim = (id: any,employeeId:any,treatmentAmount:any)=> {
  return axios
    .put(`${CLAIM_URL}/adminApproval/${id}`,{employeeId,treatmentAmount},getToken())
    .then((response) => {
      
      return response
    })
   
}
const updateOtherCharges = (id: any,amt:any)=> {
  return axios
    .get(`${CLAIM_URL}/othercharges/AddForIndividual/${id}/${amt}`,getToken())
    .then((response) => {return response})
   
}

const getCRUD = (roleId: any,moduleId:any)=> {
  return axios
    .post(`${RA_URL}/getModuleWise/CRUD`,{roleId,moduleId},getToken())
    .then((response) => {return response})
   
}
const getClinicCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`,getToken())
    .then((response) => {return response})
   
}
const getCurretBlanceAmountById = (id: any,cid:any) => {
  return axios
    .get(`${API_URL}/claimform/balance/CurrentBalance/${id}/${cid}`)
    .then((response) => response.data)
    
}
export {
  getClaimform,
  deleteClaimFormById,
  deleteSelectedClaim,
  getCompanyById,
  createClaimform,
  updateCompany,getCRUD,
  fetchData,updateOtherCharges,
  getMc,getfamilymember,fetchapproveclaim,getClaimformPagination,
  getTreatement,getClaimformById,getTreatementDetails,getMCDetails,getClinicCategory,
  getCurretBlanceAmountById,approveSelectedClaim,getBalanceDetails
}
