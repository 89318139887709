import { useContext, useEffect } from 'react'
import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {RoleListWrapper} from './role-list/RoleList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Role list',
    path: '/clinic-roles',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClinicRolePage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Role list</PageTitle>
            
              <RoleListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default ClinicRolePage
