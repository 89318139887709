import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import { getToken } from '../../../auth' 

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`
var AuthVerify = getToken.call(this)



const getCompany = async () => {
  const res = await axios.get(`${REPORT_URL}/getCompanydropdown`, getToken())
  return res;
}

const getCliniCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`,getToken())
    .then((response) => {return response})
   
}
const getClinic= async () => {
  const res = await axios.get(`${REPORT_URL}/getClinicdropdown`, getToken())
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${REPORT_URL}/clientReport/getAssignedPlans/${id}`, getToken())
  return res;
}
const getEmployee = async () => {
  const res = await axios.get(`${REPORT_URL}/getEmployeedropdown`, getToken())
  return res;
}
const getTreatement = async () => {
  const res = await axios.get(`${CLAIM_URL}/getTreatment`, getToken())
  return res;
}
const claimTreatements = async (id:any) => {
  const res = await axios.get(`${CLAIM_URL}/claimTreatements/${id}`, getToken())
 // 
  return res;
}

const getReportforCompany = async (CID:any,COMID:any,CLINICID:any,date:any) => {
  const res = await axios.post(`${REPORT_URL}/getClaimReport/${CID}/${COMID}/${CLINICID}`,{date}, getToken())
  return res;
}
const getReportforCompanyNotIn = async (CID:any,COMID:any) => {
  const res = await axios.get(`${REPORT_URL}/getNonClaimEmployee/${CID}/${COMID}`, getToken())
  return res;
}
const getCliniCategoryByplan=
async (id:any) => {
  const res = await axios.get(`${API_URL}/clinicCategory/getClinicCategoryByplan/${id}`,getToken())
  return res;
}
export {
  getCompany,getEmployee,getClinic,getReportforCompany,claimTreatements,getPlan,getReportforCompanyNotIn,
  getTreatement,getCliniCategory,getCliniCategoryByplan
}
