/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { recentSubsob } from '../core/_request'
import {listRecentSubsob, sobinitialValues } from '../core/_models' 
import {  Link, useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar'
import { useAuth } from '../../modules/auth';
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests';

type Props = {
  className: string 
}

const SOBTable: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<listRecentSubsob>(sobinitialValues);
  const [recentsubsob, setRecentsubsob] =  useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentSubsob>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
    }
  useEffect(() => {
    recentSubsob().then((res: any) => {
     
      setRecentsubsob(res.data.Data)
     
    });
  }, [])
  
  const viewSubsobDetails = (Id:any) => {
    navigate('/planInfo/view/' + Id)
  }

  const {currentUser}=useAuth();
  
  const [subsobaccess, setsubsobaccess] = useState<any>([]);
   
  
 
  useEffect(() => {
    getCRUD(currentUser?.role,17).then((res: any) => {
      var crudres=res.data?.Data[0];
      setsubsobaccess(crudres);
  
    })
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Plan</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>List of recently created Plan</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        {subsobaccess?.list===1 ? (
          <Link 
          to='/planInfo/list'
          className='btn btn-sm text-white'
          style={{background: '#9E2A8D'}}
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
        >
          <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3 text-white' />
         View All
          </Link>
        ):("")} 

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                
                <th className='min-w-150px'>Plan Name</th>
                <th className='min-w-140px'>Limits</th>
                <th className='min-w-120px'>Created On</th>
                {subsobaccess?.view===1 ? ( <th className='min-w-100px text-end'>Actions</th>):("")}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {recentsubsob && recentsubsob?.map((item:any) => {
             var date=item.createdAt;
             var dates = new Date(date)
              const formattedDate = dates.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric"
              })
             return(
              <tr key={item.id}>
                
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-20px me-5'>
                    <Avatar size="40"  round="20px"name={item.name} maxInitials={2}/>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark fw-bold  fs-6'>
                       {item.name}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {item.description}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a  className='text-dark fw-bold  d-block fs-6'>
                  {item.limit}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Per Employee
                  </span>
                </td>
                <td >
                {formattedDate}
                </td>
                {subsobaccess?.view===1 ? ( <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a    onClick={() => viewSubsobDetails(item.id)}
                      
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/eye.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    
                    {/* <a
                      
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
                  </div>
                </td>):("")}
              </tr>
                ) 
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
      
export { SOBTable }
