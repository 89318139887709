import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClinicListHeader} from './components/header/ClinicListHeader'
import {ClinicTable} from './table/ClaimformTable'
import {KTCard} from '../../../../../_metronic/helpers'
import { useLocation } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AlertBox } from '../../../../../_metronic/layout/components/alert-message'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { ReportContent } from '../nonpanel-claim-form/ReportContent'

const ClinicList = () => {
  const{show,message,type}=useContext(AlertContext);
  return (
    <>
     {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
      <KTCard>
        <ClinicListHeader />
        <ClinicTable />
      </KTCard>
    </>
  )
}

const ClaimListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClinicList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)
const ReportClaimListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReportContent />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)
export {ClaimListWrapper,ReportClaimListWrapper}
