/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {recentClaims} from '../core/_request'
import {listRecentClinics, initialValues} from '../core/_models'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {getCRUD} from '../../modules/apps/companies/company-list/core/_requests'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  className: string
}

const Table: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [data, setData] = useState<listRecentClinics>(initialValues)
  const [clinics, setClinics] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentClinics>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  var clinicsID = currentUser?.primaryID

  useEffect(() => {
    recentClaims(clinicsID).then((res: any) => {
      setClinics(res.data.Data)
      //
    })
  }, [])

  const [claimaccess, setclaimcaccess] = useState<any>([])

  useEffect(() => {
    getCRUD(currentUser?.role, 24).then((res: any) => {
      var crudres = res.data?.Data[0]
      setclaimcaccess(crudres)
    })
  }, [])
  // const viewClinicDetails = (Id:any) => {
  //   navigate('/clinics/view/' + Id)
  // }
  const viewDetails = (primaryId: any) => {
    if (claimaccess?.view === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      navigate('/portal-claim-form/view/' + primaryId)
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Claim Form</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            List of recently created claim form
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all data'
        >
          {claimaccess?.list === 1 ? (
            <Link
              to='/portal-claim-form/list'
              className='btn btn-sm text-white'
              style={{background: '#9E2A8D'}}
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3 text-white' />
              View All
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-150px'>Worker Name</th>
                <th className='min-w-150px'>Agency Name</th>
                <th className='min-w-120px'>Amount ( RM ) </th>
                <th className='min-w-140px'>Status</th>
                {claimaccess?.view === 1 ? <th className='min-w-100px text-end'>Actions</th> : ''}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {clinics.length > 0 ? (
                clinics?.map((item: any) => {
                  return (
                    <tr key={item?.id}>
                      <td>
                        {new Date(item?.visitDate).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </td>
                      <td>
                        {item?.fromName}
                        <br></br>
                        {item?.employeeId}
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <Avatar
                              size='40'
                              round='20px'
                              name={item?.client?.clientName}
                              maxInitials={2}
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a  className='text-dark fw-bold  fs-6'>
                              {item?.client?.clientName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>{item?.amount}</td>
                      <td>
                        {' '}
                        <div className='text-dark fw-bold  fs-6'>
                          {item.approvalStatus == '1' ? (
                            <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
                              Approved
                            </a>
                          ) : (
                            <a className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-8 py-1 px-3'>
                              Pending
                            </a>
                          )}
                        </div>{' '}
                      </td>
                      {claimaccess?.view === 1 ? (
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              onClick={() => viewDetails(item?.id)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/eye.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </div>
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr style={{textAlign: 'center'}}>
                  <td colSpan={5}>No Data</td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Table}
