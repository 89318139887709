/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { ListClinic } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../auth'
import { getCRUD } from '../../../../companies/company-list/core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  clinic: ListClinic
}

const SupportActionsCell: FC<Props> = ({ clinic }) => {
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);


  const viewDetails = () => {
    if (roleaccess?.view === 0) {
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px'
        },
        closeButton: false,

      })
    } else {
      navigate('/workerSupport/view/' + clinic.primaryId)
    }
  }
  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px'
        },
        closeButton: false,

      })
    } else {
      setShow(true);
    }
  }

  const { currentUser } = useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);

  useEffect(() => {
    getCRUD(currentUser?.role, 43).then((res: any) => {
      var crudres = res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  return (
    <><ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a onClick={viewDetails}
         
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG
            path='/media/icons/duotune/general/eye.svg'
            className='svg-icon-3'
          />
        </a>


      </div>


    </>
  )
}

export { SupportActionsCell }
