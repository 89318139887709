/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {ListClinic} from '../../core/_models'
import Avatar from 'react-avatar';

type Props = {
  ListSup: ListClinic
}

const ClinicInfoCell: FC<Props> = ({ListSup}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    
    { new Date(ListSup?.createdAt).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })
                 }
  </div>
)

export {ClinicInfoCell}
