import React, {useContext, useEffect, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createCategory, getCategory} from '../role-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {getCRUD} from '../../companies/company-list/core/_requests'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useQueryResponse} from '../role-list/core/QueryResponseProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
export interface IProfileDetails {
  category: string
}

export let initialValues: IProfileDetails = {
  category: '',
}
const categoryDetailsSchema = Yup.object().shape({
  category: Yup.string().required('categery name is required'),
})

const CategoryDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const {currentUser} = useAuth()
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    /*getCRUD(currentUser?.role,1).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);
      
      let id=currentUser?.Id;
      setCurrentUserId(id);
    }) */
  }, [])

  const [loading, setLoading] = useState(false)
  /*  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: categoryDetailsSchema,
    onSubmit: (values,{resetForm},) => {
      
      if(roleaccess?.add===1){
      setLoading(true)
      
      const updatedData = Object.assign(data, values)
    
      createCategory(values).then((res:any)=>{ 
        
        
        initialValues = 
        { 
        category:'',
        }
      resetForm({
        ...initialValues,
        values: { ...initialValues },
      }); 
       setData(initialValues);
       navigate('/employees/add', { replace: true });
       refetch();
       setLoading(false) ; 
       showAlert(res?.Message,res?.Code);
      })  
    }else{
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color:'#c7c7d7',
          fontSize:'14px'
        },
        closeButton: false,
      })
    }
    },
    enableReinitialize : true
  }) */

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: categoryDetailsSchema,
    onSubmit: (values, actions) => {
      actions.resetForm({
        values: initialValues,
      })
      
      setLoading(true)
      setTimeout(() => {
        const data = {name: values.category}
        createCategory(data, currentUser?.Id).then((res) => {
          
          showAlert(res?.Message, res?.Code)
          refetch()
          //navigate('/claim-form/list', {replace: true})
        })
        setLoading(false)
      }, 1000)
    },

    enableReinitialize: true,
  })

  // 
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Category </h3>
        </div>
      </div>
      <ToastContainer />
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Category Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter category name'
                    id='roleName'
                    {...formik.getFieldProps('category')}
                  />
                  {formik.touched.category && formik.errors.category && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.category}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit'     className='btn btn-sm text-white'
           style={{background: '#9E2A8D'}} disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CategoryDetails
