import React, {useContext, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createRole, updateRole} from '../role-list/core/_requests'
import {  useNavigate } from 'react-router-dom';
import { MyContext } from '../role-list/ContextFile'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
 
export interface IProfileDetails {
  name: string
}

export let initialValues: IProfileDetails = {
  name: '',
}
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Role name is required'),
   
})

const RoleDetails: React.FC = () => {
  const {showAlert}=useContext(AlertContext)
  const { state,setState } = useContext(MyContext);
  const [data, setData] = useState(state)
  const initialValues=state;
  const navigate = useNavigate();
  
      
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values,{resetForm},) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      
      updateRole(updatedData.name,state.primaryId).then((res)=>{ 
       
       setData(initialValues);
       showAlert(res?.Message,res?.Code);
       navigate('/clinic-roles', { replace: true });
      
      })
    },
    enableReinitialize : true
  })
 // 
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Role </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
             

            {/** Company Info */}
            <div className='row mb-6'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Role Name</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter role name'
                      id="roleName"
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>

                  
                </div>
               
            </div>

            
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Update Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails
 

