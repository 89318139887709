 
 
import {AccessDeniedContent} from './table/AccessDeniedContent'
import {KTCard} from '../../../../../_metronic/helpers'

const AccessDenied = () => {
  return (
    <>
      <KTCard>
         
        <AccessDeniedContent />
      </KTCard>
    </>
  )
}

const AccessDeniedWrapper = () => (
  
        <AccessDenied />
      
)

export {AccessDeniedWrapper}
