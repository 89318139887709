import React, {useEffect, useRef, useState} from 'react'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {getCompanyReport} from '../clint-report-list/_requests'

type Props = {}
const CompanyCountPage: React.FC<Props> = ({}) => {
  const [cmpDetails, setcmpDetails] = useState<any>([])
  const {id} = useParams()
  // ... (other code)
  const style2 = {
    marginTop: '20%', // example value, adjust as needed
  }
  useEffect(() => {
    getCompanyReport(id).then((res: any) => {
      setcmpDetails(res.data.Data)
    })
  }, [])
 
  return (
    <div className=''>
      {/* begin::Header */}
      <div>{/* ... (Header content) */}</div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div>{/* Your chart component here */}</div>
        {/* end::Chart */}

        <div className='flex-grow-1' style={{height: '100%'}}>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
            {/* ... (Any other components) */}
          </div>

          <div className='d-flex flex-wrap flex-stack' style={{flexWrap: 'wrap'}}>
            <div className='d-flex flex-column flex-grow-1 pe-8'>
              <div className='d-flex flex-wrap'>
                {/* Sample Card 1 */}
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3 ' >
                  <div className='symbol-1px me-1 '>
                  <img src={toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')} alt='' className='theme-light-show h-35px'/>
                  <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark049.svg')} alt='' className='theme-dark-show h-35px'/>
                    
                  </div>

                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalEmployees}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Workers</div>
                </div>

                {/* Sample Card 2 */}
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3'>
                  <div className='symbol-1px me-1'>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gen055.svg' )} alt='' className='theme-light-show h-35px' />
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark055.svg' )} alt='' className='theme-dark-show h-35px' />
                  </div>
                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalInvoice}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Invoices</div>
                </div>
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3'>
                  <div className='symbol-1px me-1'>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gen030.svg')} alt=''  className='theme-light-show h-35px' />
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark030.svg')} alt=''  className='theme-dark-show h-35px' />
                  </div>
                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalSupport}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Support</div>
                </div>
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3'>
                  <div className='symbol-1px me-1'>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gen032.svg')} alt='' className='theme-light-show h-35px' />
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark032.svg')} alt='' className='theme-dark-show h-35px' />
                  </div>
                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalVisit}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Visits</div>
                </div>
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3'>
                  <div className='symbol-1px me-1'>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gen054.svg')} alt='' className='theme-light-show h-35px'/>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark054.svg')} alt='' className='theme-dark-show h-35px'/>
                  </div>
                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalNonpanel}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Non Panel Claims</div>
                </div>
                <div className='card flex-grow-1 py-3 px-4 me-6 mb-3'>
                  <div className='symbol-1px me-1'>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gen056.svg')} alt='' className='theme-light-show h-35px'/>
                    <img src={toAbsoluteUrl('/media/icons/duotune/general/gendark056.svg')} alt='' className='theme-dark-show h-35px'/>
                  </div>
                  <div className='d-flex align-items-center' style={style2}>
                    <div className='fs-2 fw-bolder'>{cmpDetails?.totalpanel}</div>
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>Total Panel Claims</div>
                </div>
                {/* ... (Other cards) */}
              </div>
            </div>

            {/* ... (Other components) */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CompanyCountPage}
