import {useEffect, useRef, useState} from 'react'
 
 
import { getClinic, getReportforClinic } from '../_requests'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useAuth } from '../../../../../auth';
import { getClinicById } from '../../../../clinics/clinic-list/core/_requests';
const ClinicReportContent = () => {
  const {currentUser} =useAuth()
  
  var clinicsID=currentUser?.primaryID;
  const tableRef = useRef(null);
  const [cli, setCli] = useState<any>([])
   
  const [report, setReport] = useState<any>([])
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [dateRange, setDateRange] = useState([null, null]);
  const [OCcli, setOCcli] = useState('');
  const [OCDate, setOCDate] = useState('')
  const [startDate, endDate] = dateRange;
  const [loading, setLoading] = useState(false) 
  useEffect(() => {
    getClinicById(clinicsID).then((rest: any) => {
      //
     setOCcli(rest.Data?.name)
    })
  }, [])  
  useEffect(() => {
    getClinic().then((res: any) => {
      setCli(res.data.Data)
    })
  }, [])  
  
const clinicReport=()=>{
 setLoading(true)
var Daterange= (document.getElementById('Daterange') as HTMLInputElement).value;

getReportforClinic(clinicsID,Daterange,3).then((resRep)=>{
  
  setReport(resRep.data.Data);
  if(resRep.data?.Data.length>0){
    setbtnExcelDisable(false)
    setbtnClearDisable(false)
  }
  else{
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  setLoading(false)
})

setOCDate(Daterange);
 
}
var DatefileName=new Date();
var fileName = DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate();
const clearReport=()=>{
  setReport([]);
  setbtnExcelDisable(true)
  setbtnClearDisable(true)
  setCli([]); 
  setDateRange([null, null]);
  setOCDate('');
 
} 
//
//
const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename:`Clinic_Report_${fileName}`,
  sheet: "Users"
});
  return (
    <>
    
    <div className='card '>
      {/* begin::Header */}
      <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Clinic Report</h3>
          </div>
            <div className='col-lg-2 pt-5'>

                 <button       className='btn btn-sm text-white col-lg-12'
                 style={{background: '#9E2A8D'}} disabled={btnExcelDisable} onClick={onDownload}> Export </button>

            </div>
         
        </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='row mb-10'>
             
               
              <div className='col-lg-4'>
                <label  className=' col-form-label  fw-bold fs-6'>Filter By Date Range</label>
                <DatePicker className='form-control form-control-lg form-control-solid'  id="Daterange" 
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update:any) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                  />
              </div>
            <div className='col-lg-4'></div>
              
              <div className='col-lg-2 pt-12'>
                
              <button
                onClick={clinicReport}
                className='btn text-white col-lg-12' style={{background: '#9E2A8D'}} 
              >
                Search
              </button>
              </div>
              <div className='col-lg-2 pt-12'>
                
                <button
                  onClick={clearReport} disabled={btnClearDisable}
                  className='btn text-white col-lg-12 ms-2' style={{background: '#9E2A8D'}} 
                >
                  Clear
                </button>
                </div>
              
              
        </div>

        <div className='table-responsive' >
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' ref={tableRef}>
            {/* begin::Table head */}
            <thead>
            <tr className='fw-bold text-muted' style={{borderBottom:"none"}}>
                <th className='min-w-50px' colSpan={5}>  CLINIC : {OCcli}</th>
                <th className='min-w-120px' colSpan={5}>VISIT DATE : {OCDate}</th>
               </tr>
              <tr><td  colSpan={10}></td> </tr>
              <tr className='fw-bold text-muted'>
                <th className='min-w-50px'>   NO</th>
                <th className='min-w-140px'>	 NAME</th>
                <th className='min-w-140px'>	 RELATION</th>
                <th className='min-w-120px'>	NRIC</th>
                <th className='min-w-140px'>	Agency</th>
                <th className='min-w-100px'> PLAN </th>
                
                <th className='min-w-100px'> VISIT DATE </th>
                <th className='min-w-100px'> DIAGNOSIS</th>
                <th className='min-w-100px'> MC</th>
                <th className='min-w-100px'> AMOUNT (RM)</th>
              </tr>
              
            </thead>
            {loading && 
            <tbody><tr >
            <td colSpan={10} style={{textAlign:"center"}}>    Please wait...{' '} </td>
              
            </tr></tbody>
             }  
          {!loading && (
            <tbody>
           
            {report?.map((item: any,i:any) => {
              
              return(
                <tr key={i}>
                <td> {i+1}</td>
                <td>  {item?.employeeName}<br></br>( {item?.employeeId} )</td>
                <td> 
                {item.familymemberId=="0" ? ("Self" ) : (item.familymember?.relationship)}
                </td>
                <td> 
                {item?.nricPassport}
                </td>
                <td> {item.client?.clientName}  </td>
                <td>  {item.plan?.planName} </td>
                
                <td>  { new Date(item.visitDate).toLocaleDateString("en-GB", { day: "numeric",  month: "short",year: "numeric" }) }</td>
                 <td>   {item?.diagDetails.map((items: any,i:any) => {
        if(item?.diagDetails.length==i+1){ var slash="";}else{  var slash="/ ";};
        return(
          <>
           <a  key={i}> {items?.medicalcondition?.name} </a> 
         <span >{slash}</span>
          </>
         
        )
      
      })}</td>
                 <td>  {item.totalMcDays} </td>
                <td>  {item.amount}  </td>
               </tr>
             )})} 
                  {report.length>0 ? (
                <tr>
                <td colSpan={8} >  </td>
                  <th> TOTAL </th>
                <th> {(report?.reduce((a:any,v:any) =>  a = a + parseInt(v.amount) , 0 )) }</th>
                </tr>
                 ):(<tr >
                  <td colSpan={10} style={{textAlign:"center"}}> No Data </td>
                   </tr>)}
             </tbody>  )}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </>
       
       
  )
}

export {ClinicReportContent}
