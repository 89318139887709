import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Clinic, ClinicQueryResponse, ListClinic} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const CLINIC_URL = `${API_URL}/clinic`
const GET_CLINIC_LIST = `${API_URL}/clinic/query`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
 
//const DEL_CLINIC_URL=`${API_URL}/clinic`
var AuthVerify=getToken.call(this);

//
const getClinic = (query: string): Promise<ClinicQueryResponse> => {
  return axios
    .get(`${GET_CLINIC_LIST}?${query}`,getToken())
    .then((d: AxiosResponse<ClinicQueryResponse>) => {
     return d.data
    })
} 

const getClinicById = (id:any) => {
  return axios
    .get(`${CLINIC_URL}/${id}`,getToken()) 
    .then((response) =>{
      return response.data;
    }
   )
}
const getEditClinicById = (id:any) => {
  return axios
    .get(`${CLINIC_URL}/edit/view/clinic/${id}`,getToken()) 
    .then((response) =>{
      return response.data;
    }
   )
}

const createClinic = (clinic: Clinic): Promise<Clinic | undefined> => {
  return axios
    .post(CLINIC_URL, clinic,getToken())
    .then((response) => {
      return response.data
     })
   
}
const updatecontrols = (data:any,clinicuserId:any)=> {
  return axios
    .post(`${CLINIC_URL}/updateMailSend/${clinicuserId}`, data,getToken())
    .then((response) => {
      
      return response.data
     })
   
}
const updateClinic = (clinic: Clinic,clinicId:any): Promise<Clinic | undefined> => {
  return axios
    .put(`${CLINIC_URL}/${clinicId}`, clinic,getToken())
    .then((response) => {
      return response.data
     })
   
}
const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${API_URL}/clinicbulkInsert/upload`, excel,getToken())
    .then((response) => {
   return response.data;
    
})}

const deleteClinicById = (id:any) => {
  return axios
    .post(`${CLINIC_URL}/${id}`,{},getToken()) 
    .then((response) =>{
    return response.data;
     }
   )
}
const getReport = (date: any) => {
  return axios
    .post(`${API_URL}/report/exportClinic`,{date}, getToken())
    .then((d) => {
     //
      return d.data
    })
}
const getCountry = async (): Promise<ClinicQueryResponse> => {
  const res = await axios.get(`${COUNTRY_URL}`, getToken());
  return res;
}
const getCity = async () => {
  const res = await axios.get(`${CLINIC_URL}/fliter/clinicCity`, getToken());
  return res;
}
const getState = async () => {
  const res = await axios.get(`${CLINIC_URL}/fliter/clinicState`, getToken());
  return res;
}
export {
  getClinic,
  deleteClinicById,
  updatecontrols,
  getClinicById,getEditClinicById,
  createClinic,updatebulkinsert,
  updateClinic,getCountry,getReport,getCity,getState
}
