// @ts-nocheck
import {Column} from 'react-table'
import {ClinicPaymentDate,ClinicPaymentStatus} from './ClinicPaymentInfoCell'
import {ClinicPaymentActionsCell} from './ClinicPaymentActionsCell'
 
import {ClinicPaymentCustomHeader} from './ClinicPaymentCustomHeader'
 
import {List} from '../../core/_models'

const insuranceHolderColumns: ReadonlyArray<Column<List>> = [
  {
    Header: (props) =><ClinicPaymentCustomHeader tableProps={props} title='#' className='' />,
    accessor: 'serialNo',
   
  },
 
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'paymentDate',
    Cell: ({...props}) => <ClinicPaymentDate clinicPDate={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Clinic Name' className='min-w-125px' />
    ),
    accessor: 'clinic.clinicName',
   
  },
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Reference' className='min-w-125px' />
    ),
    accessor: 'invoiceNumber',
    
  },
 
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Invoice Description' className='min-w-125px' />
    ),
    accessor: 'description',
    
  },
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Amount ( RM )' className='min-w-125px' />
    ),
    accessor: 'total',
  },
  
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => <ClinicPaymentStatus clinicPDate={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClinicPaymentCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClinicPaymentActionsCell id={props.data[props.row.index].id} cpay={props.data[props.row.index]} />,
  },
]

export {insuranceHolderColumns}
