 
 
import {ClientReportContent} from './table/ClientReportContent'
import {KTCard} from '../../../../../../_metronic/helpers'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context';
import { useContext } from 'react';
import { AlertBox } from '../../../../../../_metronic/layout/components/alert-message';

const ClientReport = () => {
  const { show, message, type } = useContext(AlertContext);
  return (
    <>
      <KTCard>
      {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
        <ClientReportContent />
      </KTCard>
    </>
  )
}

const OtherChargesWrapper = () => (
  
        <ClientReport />
      
)

export {OtherChargesWrapper}
