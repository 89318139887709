import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
 
import { viewEmployee } from '../client-assign-employee-list/core/_requests';
import {EditBasicDetails} from '../client-assign-employee-form/EditBasicDetails'

export const EditBasicDetailsWrapper = () => {
 const { id } = useParams();
  const [data, setData] = useState<any>(null)
  useEffect(() => { 
    viewEmployee(id).then((res) => {
     setData(res.Data)
     
    })
  }, [])
  if (data) {
    return <EditBasicDetails initialValues={data} />
  }
  return null
}
 