import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClinicListHeader} from './components/header/ClinicListHeader'
import {ClinicTable} from './table/ClinicTable'
import {KTCard} from '../../../../../../_metronic/helpers'
import {AlertBox} from '../../../../../../_metronic/layout/components/alert-message'
import {useLocation} from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'


const ClinicList = () => {
  const {show,message,type}=useContext(AlertContext)
 // 
  return (
    <>
      {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
      <KTCard>
        <ClinicListHeader />
        <ClinicTable />
      </KTCard>
    </>
  )
}

const ClinicListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClinicList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ClinicListWrapper}
