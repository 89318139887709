// MenuInner.js

import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useAuth} from '../../../../../app/modules/auth'
import {getrolewisesidemenu} from '../../../../../app/modules/apps/role-access-setting/role-setting-list/core/_requests'

export function MenuInner() {
  const [sidemenu, setsidemenu] = useState<any>([])
  const {currentUser} = useAuth()
  const currentRole = currentUser?.role

  useEffect(() => {
     getrolewisesidemenu(currentRole)
      .then((response) => {
        const sidemenuitems = response?.data?.Data || []
        const result = sidemenuitems.reduce(
          (acc: {[x: string]: any[]}, item: {module: {sbtitle: {subtitileName: any}}}) => {
            const sbtitleName = item.module.sbtitle.subtitileName
            if (!acc[sbtitleName]) {
              acc[sbtitleName] = []
            }
            acc[sbtitleName].push(item)
            return acc
          },
          {}
        )

        const restructuredData = Object.keys(result).map((key) => ({
          [key]: result[key],
        }))

        setsidemenu(restructuredData)
      })
      .catch((error) => {
        console.error('Error fetching headermenu:', error)
      })
  }, [])

  if (currentUser?.type === 1 || currentUser?.Type === 'adminEmployee') {

    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <MenuInnerWithSub
              key={i}
              title={currentSubtitle}
              to='/crafted' // Update this according to your requirements
              menuPlacement='bottom-start'
              menuTrigger="{default:'click', lg: 'hover'}"
            >
              {item[currentSubtitle].map((module: any, j: any) => (
                <MenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                />
              ))}
            </MenuInnerWithSub>
          )
        })}
      </>
    )
  } else if (currentUser?.type === 2 || currentUser?.Type === 'client') {

    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <MenuInnerWithSub
              key={i}
              title={currentSubtitle}
              to='/crafted' // Update this according to your requirements
              menuPlacement='bottom-start'
              menuTrigger="{default:'click', lg: 'hover'}"
            >
              {item[currentSubtitle].map((module: any, j: any) => (
                <MenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                />
              ))}
            </MenuInnerWithSub>
          )
        })}
      </>
    )
  } else if (currentUser?.type === 3 || currentUser?.Type === 'clinic') {

    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <MenuInnerWithSub
              key={i}
              title={currentSubtitle}
              to='/crafted' // Update this according to your requirements
              menuPlacement='bottom-start'
              menuTrigger="{default:'click', lg: 'hover'}"
            >
              {item[currentSubtitle].map((module: any, j: any) => (
                <MenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                />
              ))}
            </MenuInnerWithSub>
          )
        })}
      </>
    )
  } else {
   
    return null
  }
}
