import React, { FC, useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {  getRole, updateEmployee } from '../clinic-assign-employee-list/core/_requests'
import { AddEmployee,initialValues } from '../clinic-assign-employee-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import { useAuth } from '../../../auth'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { checkDuplicateEmail } from '../../employee/employee-list/core/_requests'



const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  roleId: Yup.string().required('Role is required'),
  email: Yup.string()
  .email("Invalid email format")
  .test('domain', 'Invalid domain name', function (value) {
    const domain = value?.split('@')[1];
    if (!domain) {
      return false;
    }
    // Define regular expression for valid domain format
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  })
  .min(3, "Minimum 3 symbols")
  .max(50, "Maximum 50 symbols")
  .required("Email is required"),

})

type Props = {
  initialValues: AddEmployee
}
export const EditBasicDetails: FC<Props> = ({initialValues}) => {
  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate();
 const {id}= useParams();
  const [role, setRole] = useState<any>([]);
  const [splittedSob, setsplittedSob] = useState<any>([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [loading, setLoading] = useState(false)
  const [Duplicate, setDuplicate] = useState("");
   //get type
   const {currentUser} = useAuth();
    
     const type=3;
    
 
   //end get type
  const formik = useFormik<AddEmployee>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values,actions) => {
      actions.resetForm({
      values: initialValues})
      setLoading(true)
      setTimeout(() => {
      
       
       //values.roleId=splittedSob;
        
        values.type=type;
        updateEmployee(values,id).then(
          (res)=>{
            showAlert(res?.Message,res?.Code);
            navigate('/workers/list', {replace: true})
          }
        )
        setLoading(false)
      }, 1000)
    },
  })
  
  useEffect(() => {
    getRole(currentUser?.primaryID).then((res: any) => {
      setRole(res.data.Data)

    });
  }, [])
  
  const checkDuplicate = async (value: string) => {
    try {
      const response = await checkDuplicateEmail(value);
      if (response?.isDuplicate === true && initialValues.email !== value) {
        setDuplicate("Email already exists");
      } else {
        setDuplicate("");
      }
    } catch (error) {
      console.error("Error checking for duplicate :", error);
    }
  };

  return (

    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Update User</h3>
          </div>
         
        </div>
       
        <div id='kt_account_profile_details'>


          <div className='card-body border-top p-9'>

            {/** Name */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            {/** Role */}
            <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
            <div className='col-lg-8'>
                  <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        {...formik.getFieldProps('roleId')}
                      >
                        <option value=''>Select a Role...</option>
                        {role.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                  </div>
            </div>
            {/** Email */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter email)'
                  {...formik.getFieldProps('email')}
                  onChange={(e) => {
                    formik.handleChange(e);
                    checkDuplicate(e.target.value);
                  }}
                />
                  <span style={{ color: "red" }}>
                    {Duplicate ? Duplicate : ""}
                  </span>
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
  
            {/** Username */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>
              <div className='col-lg-8'>
              <input
                  type='password' autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
            {/*  */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn text-white'     style={{background: '#9E2A8D'}} disabled={loading || Duplicate != ""}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            
          </div>
        </div>
      </div>
      
    </form>
  )
                    }

export default EditBasicDetails
 

