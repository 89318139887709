import axios, {AxiosResponse} from 'axios'
 
import {getToken} from '../../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const BANNER_URL = `${API_URL}`
 

var AuthVerify=getToken.call(this);

const getBackendSettings = () => {
  return axios
    .get(`${BANNER_URL}/app/planInfo/1`,getToken())
    .then(d => d.data)
} 
const updateBackendSettings = (data:any) => {
  
  return axios
    .post(`${BANNER_URL}/app/editSettingsContact`,data,getToken())
    .then(d => d.data)
} 
const updatePlanBackendSettings = (data:FormData) => {
  
  return axios
    .put(`${BANNER_URL}/app/updatePlan`,data,getToken())
    .then(d => d.data)
} 
export {getBackendSettings,updateBackendSettings,updatePlanBackendSettings}
