/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { useAuth } from '../../modules/auth'

type Props = {
  className: string
}

const Widget = ({ className }: Props) => 
{
  const {currentUser} =useAuth()
  const [deptaccess, setdeptaccess] = useState<any>([]);
  const [empaccess, setempaccess] = useState<any>([]);
 useEffect(() => {
   getCRUD(currentUser?.role,32).then((res: any) => {
    setdeptaccess(res.data?.Data[0]);
     //setTreatment(res.data.Data);

   })
 }, [])
 useEffect(() => {
  getCRUD(currentUser?.role,31).then((res: any) => {
    setempaccess(res.data?.Data[0]);
    //setTreatment(res.data.Data);

  })
}, [])
return(
 <div className={`card card-flush ${className}`}>

    <div className="card border-transparent" style={{ backgroundColor: "#1C325E" }} >

      <div className="card-body d-flex ps-xl-15">
          {(empaccess?.add===1) || (empaccess?.list===1) ? (
            <div className="m-0">

          <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
            <span className="me-2">Add or Check
              <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
              <Link to="/agency-worker/add" className="text-danger opacity-75-hover"> new worker  </Link>

                <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

              </span > here</span>
            <br />This is the master agency datas</div>


          <div className="mb-3">
          {empaccess?.add===1 ?( <Link to="/agency-worker/add"  className='btn  text-white fw-semibold me-2'
        style={{background: '#9E2A8D'}}>Add new worker </Link>):("")}
          {empaccess?.list===1 ?(  <Link to="/agency-worker/list"   className='btn  text-white fw-semibold me-2'
        style={{background: '#9E2A8D'}}>List of Worker </Link>):("")}
          </div>

          </div>
          ):(
            <div className="m-0">

            <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
              <span className="me-2">Add or Check
                <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
                  new Worker   
  
                  <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
  
                </span > here</span>
              <br />This is the master agency datas</div>
  
  
            <div className="position-relative d-inline-block text-danger mb-3">
              You Don't have permission to access this page
            </div>
  
            </div>
          )}
        


        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')} className="position-absolute me-3 bottom-0 end-0 h-200px" alt="" />

      </div>

    </div>
  </div>
)
}
export { Widget }
