import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ClientReportWrapper} from './clint-report-list/ClientReportWrapper'

import {KTCard} from '../../../../../_metronic/helpers'
 
const creportBreadcrumbs: Array<PageLink> = [
  {
    title: 'Agency Report',
    path: '/portal-agency-Report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
 const ClientReportPortalPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={creportBreadcrumbs} >Agency Report </PageTitle>
              <ClientReportWrapper />
            </>
          }
        />
        
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}
export default ClientReportPortalPage
   
