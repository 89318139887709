/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../_metronic/partials'
import {
  datewisecreditcompanyGraph,
  datewisevisitGraph,
  vistGraph,
} from './CompanyReport-list/core/_requests'

import DatePicker from 'react-datepicker'
import {useParams} from 'react-router-dom'
import {startOfYear, endOfYear} from 'date-fns'
type Props = {
  className: string
}

const GraphPageCredit: React.FC<Props> = ({className}) => {
  // State variables to hold the data and categories

  const {id} = useParams()
  const [data, setData] = useState<number[]>([])
  const [cdata, setcData] = useState<any>([])
  const [categories, setCategories] = useState<string[]>([])

  const endDates = new Date()
  const startDate = startOfYear(endDates)
  const endDate = endOfYear(endDates)
  const initialDateRange = [startDate, endDate]
  const [dateRange, setDateRange] = useState<Date[]>(initialDateRange)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, categories, data))
    if (chart) {
      chart.render()
    }

    return chart
  }
  const fetchGraphData = (startDate: any, endDate: any) => {
    if (!startDate || !endDate) {
      return // Exit if start or end date is not available
    }

    const requestData = {
      id: id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }

    datewisecreditcompanyGraph(requestData).then((response: any) => {
      const APIRES = response?.data?.Data
      const months = APIRES.map((item: {month: any}) => item.month)
      const sums = APIRES.map((item: {count: any}) => item.count)

      setData(sums)
      setCategories(months)
      setcData(response.data)

      const chart = refreshMode() // Refresh the chart with updated data
    })
  }
  useEffect(() => {
    fetchGraphData(startDate, endDate)
  }, [])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, categories, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Credit usage based on Month</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <DatePicker
            className='form-control form-control-lg form-control-solid'
            id='Daterange'
            selectsRange={true}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={(update: any) => {
              setDateRange(update)
              if (update && update.length === 2) {
                fetchGraphData(update[0], update[1])
              } else if (!update || update.length === 0) {
                // Handle reset here if needed
                // You might want to refetch data with the default date range
                fetchGraphData(startDate, endDate)
              }
            }}
            isClearable={true}
            dateFormat='dd/MM/yyyy'
            placeholderText='Filter by date range'
          />
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}

        <div className='d-flex flex-wrap flex-stack'>
          <div className='d-flex flex-column flex-grow-1 pe-8'>
            <div className='d-flex flex-wrap'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-400'>MYR</div>
                  <div className='fs-2 fw-bolder' style={{marginLeft: '10%'}}>
                    {cdata?.MaxAmount}
                  </div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Highest Credit Used</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-400'>MYR</div>
                  <div className='fs-2 fw-bolder' style={{marginLeft: '10%'}}>
                    {cdata?.MinAmount}
                  </div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Lowest Credit Used</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-400'>MYR</div>
                  <div className='fs-2 fw-bolder' style={{marginLeft: '10%'}}>
                    {cdata?.totalCredit}
                  </div>
                </div>

                <div className='fw-bold fs-6 text-gray-400'>Total Credit</div>
              </div>
            </div>
          </div>
        </div>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {GraphPageCredit}

function getChartOptions(height: number, categories: string[], data: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-success')
  const lightColor = getCSSVariableValue('--kt-success-light')

  return {
    series: [
      {
        name: 'Credit used',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ' MYR '
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
