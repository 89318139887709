import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
 

import {KTCard} from '../../../../../_metronic/helpers'
import { OtherChargesWrapper } from './other-charges-list/OtherChargesWrapper'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
 
const creportBreadcrumbs: Array<PageLink> = [
  {
    title: 'Other Charges',
    path: '/claimform-charges',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const OtherchargesPage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={creportBreadcrumbs} >Other Charges </PageTitle>
              <OtherChargesWrapper />
            </>
          }
        />
        
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default OtherchargesPage
