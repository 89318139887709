import {useListView} from '../../core/ListViewProvider'
import {ClaimListToolbar} from './ClaimListToolbar'
import {ClinicListGrouping} from './ClinicListGrouping'
import {ClinicListSearchComponent} from './ClinicListSearchComponent'

const ClinicListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <ClinicListSearchComponent />
      
      <div className='card-toolbar'>
       
        {selected.length > 0 ? <ClinicListGrouping /> : <ClaimListToolbar />}
      
      </div> */}
    </div>
  )
}

export {ClinicListHeader}
