import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {category,CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const ROLE_URL = `${API_URL}/roles`
const GET_ROLE_URL = `${API_URL}/roles/query`

var AuthVerify=getToken.call(this);

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${API_URL}/clinicCategory/query?${query}`,getToken())
    .then((d: AxiosResponse<CategoryQueryResponse>) => d.data)
}

const getClinicCategory = ()=> {
  return axios
    .get(`${API_URL}/clinicCategory/getClinicCategory`,getToken())
    .then((response) => {return response})
   
}

const createCategory = (name: any,createdBy:any) => {
  return axios
  .post(`${API_URL}/clinicCategory`,name,getToken())
  .then((response) => response.data)
}
const deleteCategoryById = (id: any) => {
  return axios
    .post(`${API_URL}/clinicCategory/${id}`, {}, getToken())
    .then((response) => {
   // 
    return response.data;

    }
    )
}

const updateCategory = (id:any,name:any,createdBy:any) => {
  return axios
    .put(`${API_URL}/clinicCategory/${id}`,{name,createdBy},getToken())
    .then((response) => {return response.data; }
    )
}
const creditReport = (id:any,Daterange:any,OCcatid:any) => {
  return axios
    .post(`${API_URL}/report/company/creditReport`,{id,Daterange,OCcatid},getToken())
    .then((response) => {return response.data; }
    )
}

export {getCategory, deleteCategoryById,createCategory,updateCategory,getClinicCategory,creditReport}
