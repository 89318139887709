 
 
import {ClientReportContent} from './table/ClientReportContent'
import {KTCard} from '../../../../../../_metronic/helpers'

const ClientReport = () => {
  return (
    <>
      <KTCard>
         
        <ClientReportContent />
      </KTCard>
    </>
  )
}

const ClientReportWrapper = () => (
  
        <ClientReport />
      
)

export {ClientReportWrapper}
