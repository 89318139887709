import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {ClinicSupport, ClinicQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const SUP_URL = `${API_URL}/support`
 
 
//const DEL_CLINIC_URL=`${API_URL}/clinic`
var AuthVerify=getToken.call(this);


//
const getClientSupport = (query: string): Promise<ClinicQueryResponse> => {
  return axios
    .get(`${SUP_URL}/AdminPanelclientIndex/query?${query}`,getToken())
    .then((d: AxiosResponse<ClinicQueryResponse>) => {
    // 
      return d.data
    })
}  
const viewclinicSupport = (primaryId:any) => {
  return axios
    .get(`${SUP_URL}/viewClinicSupport/${primaryId}`,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}  
const getSupportStatus = () => {
  return axios
    .get(`${SUP_URL}/getsupportStatus`,getToken())
    .then((response) => {
     //
      return response.data
     })
   
}
const updateclinicStatus = (statusId:any,statusNotes:any,id:any) => {
  return axios
    .post(`${SUP_URL}/updateClinicStatus/${id}`,{statusId,statusNotes},getToken())
    .then((response) => {
     //
      return response.data
     })
   
}
const updateReply = (data:any) => {
  return axios
    .post(`${SUP_URL}/ReplyClientSupport`,data,getToken())
    .then((response) => {
     //
      return response.data
     })
   
}
const getreplyList = (id:any) => {
  return axios
    .get(`${SUP_URL}/ListReplyClientSupport/${id}`,getToken())
    .then((response) => {
     //
      return response.data
     })
   
}
export {
  getClientSupport,viewclinicSupport,getSupportStatus,updateclinicStatus,updateReply,getreplyList
  
}
