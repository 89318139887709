
import AssignSubSobContent from './table/AssignSubSobContent'

const AssignSubsob = () => {
  return (
    <>
      <AssignSubSobContent />
    </>
  )
}
const AssignSubSobWrapper = () => (<AssignSubsob />)

export { AssignSubSobWrapper }
