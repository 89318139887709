// @ts-nocheck
import {Column} from 'react-table'
import {ClinicInfoCell,ClinicSatatusCell,ClinicprorityCell} from './ClinicInfoCell'
import {ClinicActionsCell} from './ClinicActionsCell'

import {ClinicCustomHeader} from './ClinicCustomHeader'
import {ListClinic} from '../../core/_models'

const clinicColumns: ReadonlyArray<Column<ListClinic>> = [
  {
    Header: (props) => <ClinicCustomHeader tableProps={props} title="#" className='' />,
    accessor: 'serialNo',
     
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Clinic Name' className='min-w-125px' />
    ),
    accessor: 'fromName',
    
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => <ClinicInfoCell ListSup={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Subject' className='min-w-125px' />
    ),
    accessor: 'subject',
  },
  
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Priority' className='min-w-125px' />
    ),
    accessor: 'supportpriority.priorityName', Cell: ({...props}) => <ClinicprorityCell ListSup={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'supportstatus.statusName', Cell: ({...props}) => <ClinicSatatusCell ListSup={props.data[props.row.index]} />,
    },
  {
  Header: (props) => (
    <ClinicCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' /> 
  ),
  id: 'actions',
  Cell: ({...props}) => <ClinicActionsCell id={props.data[props.row.index].id}  clinic={props.data[props.row.index]}  />,
  },
]

export {clinicColumns}
