import { FC, useState } from "react";
import { WithChildren } from "../../../../../../_metronic/helpers";
import {MyContext} from "./ContextFile";

export const ContextProvider : FC<WithChildren>=({ children }) => {
    const [state, setState] = useState({ primaryId: '', name: '',editclicked:false });
  
    return (
      <MyContext.Provider value={{state, setState  }}>
        {children}
      </MyContext.Provider>
    );
  };