/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {getCompanyReport} from '../clint-report-list/_requests'
import {useParams} from 'react-router-dom'
import Avatar from 'react-avatar'

type Props = {}

const PersonalDetails: React.FC<Props> = ({}) => {
  const [cmpDetails, setcmpDetails] = useState<any>([])
  const {id} = useParams()
  useEffect(() => {
    getCompanyReport(id).then((res: any) => {
      
      setcmpDetails(res.data.Data)
    })
  }, [])
  return (
    <div className='card'>
      {/* begin::Body */}
      <div className='card-body pb-0' style={{padding:'1%'}}>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-3' style={{marginTop:'8%'}}>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div
              className='symbol symbol symbol-circle symbol-150px overflow-hidden me-3'
              style={{marginLeft: '28%'}}
            >
              <Avatar
                name={cmpDetails?.info?.name}
                maxInitials={2}
                round
                color='#607458'
                fgColor='#1b1b29'
              ></Avatar>
            </div>
          </div>
        </div>
        {/* end::Avatar */}

        {/* begin::Info */}
        <div className='d-flex flex-column' style={{marginLeft: '23%'}}>
          <a  className='text-gray-800  fs-6 fw-bold'>
            {cmpDetails?.info?.name}
          </a>
        </div>
        <div className='d-flex flex-column' style={{marginLeft: '23%'}}>
          <span className='text-gray-400 fw-semibold'>{cmpDetails?.info?.companyType}</span>
        </div>
        {/* end::Info */}

        {/* end::User */}

        {/* begin::Menu */}

        {/* end::Menu */}

        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5' style={{marginTop: '20%'}}>
          {/* begin::Text */}

          <div className='accordion' id='kt_accordion_1'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                <button
                  className='accordion-button fs-4 fw-semibold'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_1'
                  aria-expanded='true'
                  aria-controls='kt_accordion_1_body_1'
                >
                  Details
                </button>
              </h2>
              <div
                id='kt_accordion_1_body_1'
                className='accordion-collapse collapse show'
                aria-labelledby='kt_accordion_1_header_1'
                data-bs-parent='#kt_accordion_1'
              >
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>
                    Client Contact : {cmpDetails?.info?.telephoneNumber}
                  </span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>Client Email : {cmpDetails?.info?.email}</span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>
                    Address : {cmpDetails?.info?.address},{cmpDetails?.info?.city},
                    {cmpDetails?.info?.state}-{cmpDetails?.info?.pincode}
                  </span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>Country : {cmpDetails?.info?.country}</span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>Website : {cmpDetails?.info?.website}</span>
                </div>
              </div>
            </div>

            <div className='accordion-item'>
              <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                <button
                  className='accordion-button fs-4 fw-semibold collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_3'
                  aria-expanded='false'
                  aria-controls='kt_accordion_1_body_3'
                >
                  POC Details
                </button>
              </h2>
              <div
                id='kt_accordion_1_body_3'
                className='accordion-collapse collapse'
                aria-labelledby='kt_accordion_1_header_3'
                data-bs-parent='#kt_accordion_1'
              >
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>
                    Contact person : {cmpDetails?.info?.primaryContact}
                  </span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>
                    Contact number : {cmpDetails?.info?.primaryTelephone}
                  </span>
                </div>
                <div className='accordion-body' style={{padding: '10px'}}>
                  <span style={{marginRight: '8px'}}>Email : {cmpDetails?.info?.primaryEmail}</span>
                </div>
              </div>
            </div>
          </div>

          {/* end::Text */}

          {/* begin::Toolbar */}

          {/* end::Toolbar */}
        </div>
        {/* end::Post */}

        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PersonalDetails}
