import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
 
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {AddRole, EditRole} from '../../role/role-form/index'
import { MyContext } from './ContextFile'
import { useContext } from 'react'
import { ContextProvider } from './ContextProvider'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { AlertBox } from '../../../../../../_metronic/layout/components/alert-message'
import { useNavigate } from 'react-router-dom'

const RolesAdd = () => {
  const { state,setState } = useContext(MyContext);
 
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
      {state.editclicked===true ? (<EditRole/>):( <AddRole/>)}
      

      </KTCard>
      
    </>
  )
}
const UsersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
       
    </>
  )
}
const RoleMain = ()=>{
  const {show,message,type}=useContext(AlertContext)
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return(
  <div className='row'>
{show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}

<div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-10 p-6'>
      <KTSVG
        path='/media/icons/duotune/general/gen048.svg'
        className='svg-icon-2tx svg-icon-success me-4'
      />
      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
        <div className='mb-3 mb-md-0 fw-bold'>
          <h4 className='text-gray-800 fw-bolder'>Role Module</h4>
          <div className='fs-6   pe-7' style={{color: "#ffc700"}}>
            Step 1: You can add Roles here.
          </div>
          <div className='fs-6 text-gray-600 pe-7'>
            Step 2: Create user for this role and create new username & password for this role here
          </div>
          <div className='fs-6 text-gray-600 pe-7'>
            Step 3: Create roles & permission for this role here.
          </div>
        </div>
        
      </div>
    </div>   
  <div className='col-lg-6'>
  <RolesAdd />
  </div>
  <div className='col-lg-6'>
  <UsersList />
  </div>
  </div>
  )
}

const RoleListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
      <ContextProvider>
     <RoleMain/>
     </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RoleListWrapper}
