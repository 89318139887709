import {useListView} from '../../core/ListViewProvider'
import {InsuranceHolderListToolbar} from './ClinicPaymentToolbar'
import {InsuranceHolderListGrouping} from './ClinicPaymentGrouping'
import {InsuranceHolderListSearchComponent} from './ClinicPaymentSearchComponent'

const ClinicPaymentHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <InsuranceHolderListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <InsuranceHolderListGrouping /> : <InsuranceHolderListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ClinicPaymentHeader}
