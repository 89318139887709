import {ID, Response} from '../../../_metronic/helpers'
export type listRecentClinics = {
  id?:ID; 
  name: string; 
  email: string;
  telephoneNumber:string;
  state:string;
  country:string;
  pincode:string;
 
}

export type RecentClinicsQueryResponse = Response<Array<listRecentClinics>>

export const initialValues: listRecentClinics = {
 
    name: '',
    email: '',
    telephoneNumber:'',
    state:'',
    country:'',
    pincode:''
  
}

export type listRecentSubsob = {
   
 id?:ID; 
  name:string;
  limit: string;
  createdAt: string;
 
}

export type RecentSubsobQueryResponse = Response<Array<listRecentSubsob>>

export const sobinitialValues: listRecentSubsob = {
 

  name:'',
  limit: '',
  createdAt: '',
  
}
//Client
export type listRecentClients = {
  id?:ID; 
  name: string; 
  email: string;
  telephoneNumber:string;
  state:string;
  country:string;
  pincode:string;
 
}

export type RecentClientQueryResponse = Response<Array<listRecentClients>>

export const initialValuesClient: listRecentClients = {
 
    name: '',
    email: '',
    telephoneNumber:'',
    state:'',
    country:'',
    pincode:''
  
}

//Employee
export type listRecentrecentInsuranceHolder = {
  id?:ID; 
  name: string; 
  email: string;
  mobileNumber:string;
  employeeId:string;
  client: {
    name: String;
  },
  department: {
    name:String
},

}

export type RecentrecentInsuranceHolderQueryResponse = Response<Array<listRecentrecentInsuranceHolder>>

export const initialValuesIH: listRecentrecentInsuranceHolder= {
 
    name: '',
    email: '',
    mobileNumber:'',
    employeeId:'',
    client: {
      name: ''
    },
    department: {
      name:''
  },
  
}

