import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
 
import { viewBanner } from '../banner-list/core/_requests';
import EditBanner from './EditBanner';

export const EditBannerWrapper = () => {
 const { id } = useParams();
  const [data, setData] = useState<any>(null)
  useEffect(() => { 
    viewBanner(id).then((res) => {
     
      setData(res.Data)
    })
  }, [])
  if (data) {
    
    return <EditBanner initialValues={data} />
  }
  return null
}