import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EmployeeListHeader} from './components/header/EmployeeListHeader'
import {EmployeeTable} from './table/EmployeeTable'
import {KTCard} from '../../../../../_metronic/helpers'
import { AlertBox } from '../../../../../_metronic/layout/components/alert-message'
import { useContext } from 'react'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'


const EmployeeList = () => {
  const{show,message,type}=useContext(AlertContext);
  
  return (
    <>
    {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
      <KTCard>
        <EmployeeListHeader />
        <EmployeeTable />
      </KTCard>
    </>
  )
}

const EmployeeListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmployeeList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EmployeeListWrapper}
