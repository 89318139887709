import {Route, Routes, Outlet, Navigate,Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClaimListWrapper} from './nonpanel-claim-list/ClaimformList'
import { useNavigate } from 'react-router-dom'; 
import {KTCard} from '../../../../_metronic/helpers'
import {removeAuth, useAuth} from '../../auth'
import { ViewClaimform } from './nonpanel-claim-form/ViewClaimform'
import { AlertContext } from '../../../../_metronic/layout/components/alert-context';
import { useContext, useEffect } from 'react';
const claimformBreadcrumbs: Array<PageLink> = [
  {
    title: 'Claim Form',
    path: '/nonpanel-claims-form',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const NonPanelClaimformPage = () => {
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate();
  function goBack() {
     
    navigate(-1);
  }
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={claimformBreadcrumbs}>Non Panel Claim Form list</PageTitle>
             
             

              <ClaimListWrapper />
            </>
          }
        />
        
           <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={claimformBreadcrumbs}>View Non Panel Claim Form </PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>
                <ViewClaimform />
               
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default NonPanelClaimformPage
