import { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import { getCRUD } from '../../../../companies/company-list/core/_requests'


const EmployeeListToolbar = () => {
  const navigate = useNavigate()
  const openAddUserModal = () => {
    navigate('/assign-agency-worker/add')
  }
  const {currentUser}=useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,47).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <CompanyListFilter /> */}

      {/* begin::Add user */}
      {roleaccess?.add===1 ? (
         <button type='button'     className='btn btn-sm text-white'
         style={{background: '#9E2A8D'}} onClick={openAddUserModal}>
         <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2 text-white' />
         Add User
       </button>
      ):("")}
     
      {/* end::Add user */}
    </div>
  )
}

export {EmployeeListToolbar}
