import {ID, Response} from '../../../../../../_metronic/helpers'
export type Employee = {
  id?:ID; 
  name: string; 
  primaryId: string; 
  email: string;
  Code:string;
  Message:string;
  role:{
    roleName:string; 
  } 
   
}

export type EmployeeQueryResponse = Response<Array<Employee>>

export const initialEmployee: Employee = {
 
  name: '',
  primaryId:'',
  email: '',
  Code: '',
  Message: '',
  role:{
    roleName:'',
  } 
}
export interface AddEmployee {
  name: string,
  type:number,
  email: string,
  roleId: string,
  password: string,
  typeId: any,
  createdBy: any,
}

export const initialValues: AddEmployee = {
  name: '',
  type:0,
  email: '',
  roleId: '',
  password: '',
  typeId: '',
  createdBy: '',
}

