/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {removeAuth, useAuth} from '../../../../app/modules/auth'
 
import {toAbsoluteUrl} from '../../../helpers'
import { fetchProfile } from '../../../../app/modules/accounts/components/settings/_requests' 
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../../app/modules/accounts/components/settings/SettingsModel'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [data, setData] = useState<IProfileDetails>(initialValues)
 
 let passid = currentUser?.Id
  if (!passid) {
    passid = currentUser?.userid
  }
  useEffect(() => {
    passid &&
      fetchProfile(passid).then((res: any) => {
        setData(res.data.Data[0])
      })
  }, [passid])
  
  var MainName="";
 
  if(currentUser?.type=="1" || currentUser?.Type=="adminEmployee"){
  var MainName="Admin";
  }else if(currentUser?.Type=="clinic" || currentUser?.type=="3"){
    var MainName="Clinic";
  }
  else if(currentUser?.Type=="client" ||  currentUser?.type=="2"){
    var MainName="Agency";
  }
  const handleSignOut = ()=>{
    //debugger;
    localStorage.setItem('rakyat_auth_detail',"");
    localStorage.removeItem('rakyat_auth_detail');
   
    localStorage.clear();
    removeAuth();
    logout();
  }
 
 
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {MainName} 
            </div>
            <a  className='fw-bold text-muted  fs-7'>
              {data?.username}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>


     

      <div className='menu-item px-5 my-1'>
        <Link to='/profile/list' className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <Link to='/changePassword/list' className='menu-link px-5'>
         Change Password
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={()=>handleSignOut()} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
