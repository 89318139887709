import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import DatePicker from "react-datepicker";
const ClientInvoiceFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [dateRange, setDateRange] = useState([null, null]);
  const [duedateRange, setDueDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [duestartDate, dueendDate] = duedateRange;
  const [amtF, setamtF] = useState('')
  const [amtT, setamtT] = useState('')
  const [status, setstatus] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setamtF('');
    setamtT('');
    setstatus('');
    setDateRange([null, null]);
    setDueDateRange([null, null]);
  }

  const filterData = () => {
    var Daterange= (document.getElementById('Daterange') as HTMLInputElement).value;
    var DueDaterange= (document.getElementById('DueDaterange') as HTMLInputElement).value;
   
    updateState({
      filter: { amtF,amtT,Daterange,DueDaterange,status},
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn text-white me-3' style={{background: '#9E2A8D'}} 
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 text-white' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true' style={{ maxHeight: 'calc(400px - 80px)', overflowY: 'auto' }}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
        <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Payment Date :</label>
            <DatePicker className='form-control form-control-lg form-control-solid'  id="Daterange" 
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                   
                    onChange={(update:any,e:any) => {
                      setDateRange(update);
                       e.stopPropagation();
                    }}
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                  />
          </div>
          {/* end::Input group */}

            {/* begin::Input group */}
            <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Due Date :</label>
            <DatePicker className='form-control form-control-lg form-control-solid'  id="DueDaterange" 
                    selectsRange={true}
                    startDate={duestartDate}
                    endDate={dueendDate}
                   
                    onChange={(update:any,e:any) => {
                      setDueDateRange(update);
                       e.stopPropagation();
                    }}
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                  />
          </div>
          {/* end::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Amount Range :</label>
            <div className='row'>
            <input
                  type='text'
                  className='col-md-6 form-control form-control-lg form-control-solid mb-2' 
                  placeholder='Range from'
                  style={{marginBottom:'10px!important;'}}
                  onChange={(e:any) => {
                   setamtF(e.target.value)
                   }}
                   value={amtF}
                />
                <input
                  type='text'
                  className='col-md-6 form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Range to'
                  onChange={(e:any) => {
                    setamtT(e.target.value)
                    }} value={amtT}
                /></div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Approval Status :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option value=''>All</option>
              <option value='2'> Invoice Created</option>
              <option value='1'>Payment Submitted</option>
            </select>
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData} 
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ClientInvoiceFilter}
