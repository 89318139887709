import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'

import { getToken } from '../../../../../auth' 

const API_URL = process.env.REACT_APP_API_URLS
const ROLE_URL = `${API_URL}/roleAccess`
var AuthVerify = getToken.call(this)

 
const getRole= async (clientId:any) => {
  const res = await axios.get(`${ROLE_URL}/getClientRole/${clientId}`, getToken())
  return res;
} 
const getmodules= async () => {
    const res = await axios.get(`${ROLE_URL}/moduleClient`, getToken())
    return res;
    
  } 
  const createroleaccess= async (data:any) => {
    const res = await axios.post(`${ROLE_URL}/addRoleAccess`,{data}, getToken())
    return res;
    
  } 
  const getroleaccess= async (roleId:any) => {
    const res = await axios.get(`${ROLE_URL}/getClientPageAccess/${roleId}`, getToken())
    return res;
    
  } 
  const getrolewisesidemenu= async (roleId:any) => {
    const res = await axios.get(`${ROLE_URL}/rolewise/sidemenu/${roleId}`, getToken())
    return res;
    
  } 
export {
    getRole,getmodules,createroleaccess,getroleaccess,getrolewisesidemenu
}
