
import { useContext, useEffect, useState } from 'react'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { Link, useParams } from 'react-router-dom'
import {  getBackendSettings } from './core/_requests'


const ListPage = ()=>{
  const{showAlert}=useContext(AlertContext);



  
    const [settinglist, setsettinglist] = useState<any>([])
    const fetchData = () => {
      getBackendSettings()
        .then((res: any) => {
          
          setsettinglist(res);
        })
        .catch((error: any) => {
          // Handle error if necessary
        });
    };
    
    useEffect(() => {
      fetchData();
    }, []);


  return(
    <>
   
      
      
    
    
          <div className='card mb-5 mb-xl-10' >
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Contact Settings</h3>
              </div>
          
            <Link to='/mobile-settings/banner/editcontact' className='btn btn-primary align-self-center'>
            Edit Contact
          </Link> 
         
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Emergency Contact</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                 {settinglist?.adminEmergencyContact}
                  </a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Ambulance Contact</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  {settinglist?.ambulanceContact}
                  </a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Fire Contact</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  {settinglist?.fireContact}
                  </a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>About Content</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  {settinglist?.about}
                  </a>
                </div>
              </div>
              
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Plan Info Settings</h3>
              </div>
            <Link to='/mobile-settings/banner/editplan'  className='btn text-white align-self-center' style={{background: '#9E2A8D'}}>
            Edit Plan
          </Link> 
            </div>
            <div className='card-body p-9'>
            
              <div className='row'>
                
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Plan Image</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  <img src={settinglist?.planInfoImage} style={{height:'100px',width:'150px'}}/>
                  </a>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Plan Title</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  {settinglist?.planInfoTitle}
                  </a>
                </div>
              </div>  
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Plan Details</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>
                  {settinglist?.PlanInfoDetails}
                  </a>
                </div>
              </div> 
                  
                
              </div>
           
            </div>
          </div>
        
      
       
    
   </>
 
   )
}
 

export {ListPage}
