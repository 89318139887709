/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {StatisticsWidget1,EngageWidget11} from '../../../_metronic/partials/widgets'
import {StatisticsWidget3} from './StatisticsWidget3'
 
import {Widget} from './Widget'
import {EmployeeTable} from './EmployeeTable'
import React , { FC,useEffect, useState }from 'react'
import { clientportalEmpCount } from '../core/_request'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { getCompanyById, getCRUD } from '../../modules/apps/companies/company-list/core/_requests'


// const usersBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Dashboard',
//     path: '/dashboard',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const ClientPortalWrapper: FC = () => {
  const intl = useIntl()
  const { currentUser} =useAuth();
  //Data Set
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
  const [com, setCom] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }
  
  useEffect(() => {
    clientportalEmpCount(currentUser?.primaryID).then((res: any) => {      
    //  debugger
    
      setCount(res.data.data)   
    });
  }, [])
  useEffect(() => {
    getCompanyById(currentUser?.primaryID).then((res: any) => {
    setCom(res.Data)
    });
  }, [])

  const [deptaccess, setdeptaccess] = useState<any>([]);
  const [empaccess, setempaccess] = useState<any>([]);
 useEffect(() => {
   getCRUD(currentUser?.role,32).then((res: any) => {
    setdeptaccess(res.data?.Data[0]);
     //setTreatment(res.data.Data);

   })
 }, [])
 useEffect(() => {
  getCRUD(currentUser?.role,31).then((res: any) => {
    setempaccess(res.data?.Data[0]);
    //setTreatment(res.data.Data);

  })
}, [])
  return (
    <>
    <div className='card-header  row  '> 
      <div className='border-0 col-xl-7 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
      
      </div>
      <div className="row mb-5  col-xl-5 col-12 col-sm-12 col-md-12 px-0">
      {empaccess?.add===1 ? (
        <div
        className='px-0 col-xl-6  col-6 col-sm-6 col-md-6 card-toolbar'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
        title='Click to add  worker'
      ><Link   style={{float: "right",background: '#9E2A8D'}}
      to='/agency-worker/add'
      className='btn btn-sm text-white '
      // data-bs-toggle='modal'
      // data-bs-target='#kt_modal_invite_friends'
    >
      <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
      New Worker
      </Link>
      </div>
       ):("")} 
        {deptaccess?.list===1 ? (
        <div
          className='px-0 col-xl-6  col-6 col-sm-6 col-md-6 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add department'
        ><Link   style={{display: "block",background: '#9E2A8D'}}
        to='/agency-department/list'
        className='btn btn-sm text-white col-lg-11 ms-2'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3 text-white' />
        Manage Departments
        </Link>
        </div>):("")}
      </div>
      </div>
      <>

       {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget1
          className='card-xl-stretch mb-xl-8'
          image='abstract-4.svg'
          title={"Welcome to "+com?.name}
          time={
            new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric"
              })
            }
          description='Manage and configure your agency <br/>setting here'
        />
      </div>
      <div className='col-xl-8'>

        <Widget className="card-xl-stretch mb-xl-8" />

      </div>

    </div>
    {/* end::Row */}
    {/* begin::Row */}
    
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='danger'
          title='Total Workers'
          description='Total number of Workers'
          change={count?.totalEmployeeCountPortal}
        />
      </div>
      
      
      

      
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Invoice Received'
          description='Estimated total invoice received'
          change={count?.paymentCount}
        />
      </div>
    </div>
    {/* end::Row */}
   
     
    <div className='col-xl-12'>
      <EmployeeTable
        className='card-xl-stretch mb-5 mb-xl-8'

      />
      
    </div>

  </>
    </>
  )
}





export { ClientPortalWrapper }
