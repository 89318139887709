import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {IProfileDetails,IUpdatePassword} from './SettingsModel'
import {getToken} from '../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const PFL_URL = `${API_URL}/login/getProfile`
const UP_PFL_URL = `${API_URL}/login/profile/updateProfile`
const UP_PP_URL = `${API_URL}/login/profile/updatePassword`
 
var AuthVerify=getToken.call(this);

 

const fetchProfile = async (id: string | undefined) => {
  const res = await axios.post(`${PFL_URL}/${id}`, getToken());
 
  return res;
}
 
const updateProfile = (profile: any): Promise<any | undefined> => {
  return axios
    .post(`${UP_PFL_URL}/${profile.id}`, profile,getToken())
    .then((response) => response.data)
    
}
const updatePasswordInfo = (profile: any,profileId:any): Promise<any | undefined> => {
  return axios
    .put(`${UP_PP_URL}/${profileId}`, profile,getToken())
    .then((response) => response.data)
   
}
export {
  
  fetchProfile,updateProfile,updatePasswordInfo
  
}
