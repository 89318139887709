import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import DatePicker from 'react-datepicker'
import {getCompany} from '../../../../client-report/clint-report-list/_requests'
import Select, {Theme} from 'react-select'
import {useThemeMode} from '../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
const NonpanelListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [dateRange, setDateRange] = useState([null, null])
  const [SdateRange, setSDateRange] = useState([null, null])

  const [com, setCom] = useState<any>([])
  // const [selectedOption, setSelectedOption] = useState(null)
  const [comId, setcomId] = useState<string | undefined>()
  const [amtF, setamtF] = useState('')
  const [amtT, setamtT] = useState('')
  const [status, setstatus] = useState<string | undefined>()
  const [startDate, endDate] = dateRange
  const [SstartDate, SendDate] = SdateRange

  const {mode} = useThemeMode()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})

    setcomId('')
    setamtF('')
    setamtT('')
    setstatus('')
    setDateRange([null, null])
    setSDateRange([null, null])
  }
  useEffect(() => {
    getCompany().then((res: any) => {
      setCom(res.data.Data)
      //setCom(res.data.Data)
      // const mappedOptions = res.data.Data.map((com: { id: any; name: any; }) => ({ value: com.id, label: com.name }));
      // const allOption = { value: 0, label: "All" };
      // const optionsWithAll = [allOption, ...mappedOptions];
      // 
    })
  }, [])

  const handleCompanyChange = (selectedOption: any, event: any) => {
    //event.preventDefault();
    setcomId(selectedOption?.value)
  }
  const filterData = () => {
    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    var SDaterange = (document.getElementById('SDaterange') as HTMLInputElement).value
    updateState({
      filter: {comId, amtF, amtT, status, Daterange, SDaterange},
      ...initialQueryState,
    })
  }
  
  //dropdown

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn text-white me-3' style={{background: '#9E2A8D'}} 
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 text-white' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px ' data-kt-menu='true' style={{ maxHeight: 'calc(400px - 80px)', overflowY: 'auto' }}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Agency :</label>
            {/* <Select
      className="my-react-select-container"
      classNamePrefix="my-react-select"
      value={selectedOption}
      onChange={ handleCompanyChange}
      options={com}
      isSearchable 
    
     styles={customStyles}
      /> */}
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setcomId(e.target.value)}
              value={comId}
            >
              <option value=''>All</option>
              {com.map((item: any, i: any) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Visit Date :</label>
            <DatePicker
              className='form-control form-control-lg form-control-solid'
              id='Daterange'
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any, e: any) => {
                setDateRange(update)
                e.stopPropagation()
              }}
              isClearable={true}
              dateFormat='dd/MM/yyyy'
            />
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Submission Date :</label>
            <DatePicker
              className='form-control form-control-lg form-control-solid'
              id='SDaterange'
              selectsRange={true}
              startDate={SstartDate}
              endDate={SendDate}
              onChange={(update: any, e: any) => {
                setSDateRange(update)
                e.stopPropagation()
              }}
              isClearable={true}
              dateFormat='dd/MM/yyyy'
            />
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Amount Range :</label>
            <div className='row '>
              <input
                type='text'
                className='col-md-6 mb-2 form-control form-control-lg form-control-solid'
                placeholder='Range from'
                style={{marginBottom: '10px!important;'}}
                onChange={(e: any) => {
                  setamtF(e.target.value)
                }}
                value={amtF}
              />
              <input
                type='text'
                className='col-md-6 form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Range to'
                onChange={(e: any) => {
                  setamtT(e.target.value)
                }}
                value={amtT}
              />
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Approval Status :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option value=''>All</option>
              <option value='0'>Pending</option>
              <option value='1'>Approved</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {NonpanelListFilter}
// if(mode==="dark"){
//   var customStyles = {
//     control: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: 'var(--kt-app-bg-color)',
//       borderColor: '#393944',

//       boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
//       '&:hover': {

//         color:'red'
//       },
//     }),
//     option: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
//       color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',

//     }),
//     menu: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: 'var(--kt-app-bg-color)',
//       boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//       marginTop: 5,
//       borderRadius: 5,

//     }),
//     placeholder: (provided: any, state: any) => ({
//       ...provided,
//       color: 'var(--kt-input-solid-color)',

//     }),
//     singleValue: (provided: any, state: any) => ({
//       ...provided,
//       color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
//     }),
//    indicatorSeparator: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: 'var(--kt-input-solid-color)',
//     }),
//   };
// }
// else{
//   var customStyles = {
//     control: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: 'var(--kt-app-bg-color)',
//       borderColor: '#c5c5c5',

//       boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',

//     }),
//     option: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
//       color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',

//     }),
//     menu: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: 'var(--kt-app-bg-color)',
//       boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//       marginTop: 5,
//       borderRadius: 5,

//     }),
//     placeholder: (provided: any, state: any) => ({
//       ...provided,
//       color: 'var(--kt-input-solid-color)',

//     }),
//     singleValue: (provided: any, state: any) => ({
//       ...provided,
//       color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
//     }),
//    indicatorSeparator: (provided: any, state: any) => ({
//       ...provided,
//       backgroundColor: '#c5c5c5',
//     }),
//   };
// }
