 
 
import {ClinicReportContent} from './table/ClinicReportContent'
import {KTCard} from '../../../../../../_metronic/helpers'

const ClinicReports = () => {
  return (
    <>
      <KTCard>
         
        <ClinicReportContent />
      </KTCard>
    </>
  )
}

const ClinicReportWrapper = () => (
  
        <ClinicReports />
      
)

export {ClinicReportWrapper}
