import { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../../auth'
import { getCRUD } from '../../../../../companies/company-list/core/_requests'
import {useListView} from '../../core/ListViewProvider'
import { ClinicListFilter } from './ClinicListFilter'

const ClinicListToolbar = () => {
  const navigate = useNavigate()
  const openAddUserModal = () => {
    navigate('/portal-agency-support/add')
  }
  const {currentUser}=useAuth();
   
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ClinicListFilter />

      {/* begin::Add user */}
      
      {/* end::Add user */}
    </div>
  )
}

export {ClinicListToolbar}
