import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {Roles, RolesQueryResponse} from './_models'
import {getToken} from '../../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const ROLE_URL = `${API_URL}/roles`
const GET_ROLE_URL = `${API_URL}/roles/query`

var AuthVerify=getToken.call(this);

const getRole = (query: string,type:any,typeId:any): Promise<RolesQueryResponse> => {
  
  return axios
    .post(`${GET_ROLE_URL}?${query}`,{type,typeId},getToken())
    .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}


const createRole = (user: Roles) => {
  return axios
    .post(ROLE_URL, user,getToken())
    .then((response) => response.data)
}

const deleteRoleById = (id: any) => {
  return axios
    .post(`${ROLE_URL}/${id}`, {}, getToken())
    .then((response) => {
   // 
    return response.data;

    }
    )
}
const updateRole = (name:any,id: any) => {
  return axios
    .put(`${ROLE_URL}/${id}`, {name}, getToken())
    .then((response) => {
   // 
    return response.data;

    }
    )
}


export {getRole,createRole, deleteRoleById,updateRole}
