import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper} from './clinic-assign-employee-list/EmployeeList'
import {AddEmployee} from './clinic-assign-employee-form/index'
import {  Link } from 'react-router-dom';
import {  ViewEmployee } from './clinic-assign-employee-form/ViewEmployee';
import { EditBasicDetailsWrapper } from './clinic-assign-employee-form/EditBasicDetailsWrapper'
import { KTSVG } from '../../../../_metronic/helpers';
import { AlertContext } from '../../../../_metronic/layout/components/alert-context';
import { useContext, useEffect } from 'react';

const employeeBreadcrumbs: Array<PageLink> = [
  {
    title: 'User',
    path: '/assign-clinic-worker',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClinicAssignEmployeePage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
          <>
          <PageTitle breadcrumbs={employeeBreadcrumbs}> User list</PageTitle>
          <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
            <EmployeeListWrapper /> 
          </>
          }
        />
        <Route
          path='/add'
          element={
            <>
              <PageTitle breadcrumbs={employeeBreadcrumbs}>Create User</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-10 p-6'>
                <KTSVG
                  path='/media/icons/duotune/general/gen048.svg'
                  className='svg-icon-2tx svg-icon-success me-4'
                />
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>Assign User Module</h4>
                    <div className='fs-6 text-gray-600  pe-7' >
                      Step 1: You can add Roles here.
                    </div>
                    <div className='fs-6  pe-7' style={{color: "#ffc700"}}>
                      Step 2: Create user for this role and create new username & password for this role here
                    </div>
                    <div className='fs-6 text-gray-600 pe-7'>
                      Step 3: Create roles & permission for this role here.
                    </div>
                  </div>
                  
                </div>
              </div>
              <AddEmployee />
            </>
          }
        />
        <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={employeeBreadcrumbs}>User Overview</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
             
            <ViewEmployee />
            
            </>
          }
        />
         <Route
          path='/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={employeeBreadcrumbs}>Edit Employee</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
             
            <EditBasicDetailsWrapper />
            
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default ClinicAssignEmployeePage
