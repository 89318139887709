/* eslint-disable jsx-a11y/anchor-is-valid */
 
import {FC} from 'react'

import {ListClinic} from '../../core/_models'


type Props = {
  ListSup: ListClinic
}

const SupportInfoCell: FC<Props> = ({ListSup}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    
    { new Date(ListSup?.createdAt).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })
                }
  </div>
)
const SupporStatusCell: FC<Props> = ({ListSup}) => (
  <div className='d-flex align-items-center'>
  {/* begin:: Avatar */}
  <div className=''>
  <span className='badge badge-light-success'>{ListSup.supportstatus?.statusName}</span>
   </div> 
</div>
)
const SupporpriorityCell: FC<Props> = ({ListSup}) => (
  <div className='d-flex align-items-center'>
  {/* begin:: Avatar */}
  <div className=''>
  
          <span className='badge badge-light-warning'>{ListSup.supportpriority?.priorityName}</span>
    
     
  </div> 
  
</div>
)
export {SupportInfoCell,SupporStatusCell,SupporpriorityCell}
