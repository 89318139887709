/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { useAuth } from '../../modules/auth'

type Props = {
  className: string
}

const Widget = ({ className }: Props) =>
{
  const {currentUser}=useAuth();
  const [subsobaccess, setsubsobaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,17).then((res: any) => {
      var crudres=res.data?.Data[0];
      setsubsobaccess(crudres);
  
    })
  }, [])
return (
  <div className={`card card-flush ${className}`}>

    <div className="card border-transparent" style={{ backgroundColor: "#1C325E" }} >

      <div className="card-body d-flex ps-xl-15">
      {(subsobaccess?.add===1) || (subsobaccess?.list===1) ? (
        <div className="m-0">

          <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
            <span className="me-2">Configure and Manage
              <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
                <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

              </span ></span>
            <br /><a  className="text-danger opacity-75-hover"> Plans &  Benefits </a></div>


          <div className="mb-3">
          {subsobaccess?.add===1 ? (<Link to="/planInfo/add"  className="btn btn-danger fw-semibold me-2">Add Plan </Link>):("")}
           {subsobaccess?.list===1 ? (<Link to="/planInfo/list" className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold">List of Plan</Link>):("")}
          </div>

        </div>
      ):(
        <div className="m-0">

        <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
          <span className="me-2">Configure and Manage
            <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
              <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

            </span ></span>
          <br /><a  className="text-danger opacity-75-hover"> Plans &  Benefits </a></div>


        <div className="position-relative d-inline-block text-danger mb-3">
          You Don't have permission to access this page
        </div>

      </div>
      )}

        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')} className="position-absolute me-3 bottom-0 end-0 h-200px" alt="" />

      </div>

    </div>
  </div>
)}
export { Widget }
