/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {Link,useParams,useLocation, useNavigate } from 'react-router-dom'
import { KTSVG,toAbsoluteUrl } from '../../../../../../_metronic/helpers'
// import { getClinicById, updatecontrols } from '../clinic-support-list/core/_requests'
import {ClinicSupport,initialValues} from '../clinic-support-list/core/_models'
 
import Avatar from 'react-avatar'
import { useAuth } from '../../../../auth'
import { getCRUD } from '../../../companies/company-list/core/_requests'
import { viewclinicSupport } from '../clinic-support-list/core/_requests'
import { getreplyList } from '../../../clinic-support/clinic-support-list/core/_requests'


export function ViewClinicSupport() {
  const location = useLocation()
  const { id  } = useParams();
  const navigate = useNavigate()
   
 
const {currentUser}=useAuth();
const [roleaccess, setroleaccess] = useState<any>([]);
const [sup, setsup] = useState<any>([]);
const [replist, setreplist] = useState<any>([]);

useEffect(() => {
  getCRUD(currentUser?.role,28).then((res: any) => {
    var crudres=res.data?.Data[0];
    setroleaccess(crudres);

  })
}, [])
useEffect(() => {
  viewclinicSupport(id).then((res: any) => {
    //
    var supcont=res?.Data[0];
    setsup(supcont);

  })
}, [])

useEffect(() => {
  getreplyList(id).then((res: any) => {
    
    var supcont = res.Data;
    setreplist(supcont);

  })
}, [])

var editclinic="/clinicPortal-support/edit/"+id;
  return (
    <>
    <div className="tab-content" id="myTabContent">
      
      <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview'  role="tabpanel">
       
      <div className='card mb-5 mb-xl-10'   >
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>View Support</h3>
          </div>
          {roleaccess?.edit===1 ? (<Link to={editclinic} className='btn text-white align-self-center' style={{background: '#9E2A8D'}}>
            Edit Support
          </Link>):("")}
         
        </div>


        <div className='card-body p-9'>
          

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Date
             
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>   { new Date(sup?.createdAt).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })
                 }</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Subject
             
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{sup?.subject}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Priority
             
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
              
                <div  className='text-dark fw-bold  fs-6'>
                      <a className='btn btn-sm btn-light-warning fw-bolder   fs-8 py-1 px-3' >
                      {sup?.supportpriority?.priorityName}
                       </a>
              </div>
                </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Message
             
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{sup?.message}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Status
             
            </label>

            <div className='col-lg-8'>
            <div  className='text-dark fw-bold  fs-6'>
                      <a className='btn btn-sm btn-light-success fw-bolder   fs-8 py-1 px-3' >
                      {sup?.supportstatus?.statusName}
                       </a>
              </div>
            </div>
          </div>
          {sup?.statusnotes!=null ? ( <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Status Description
             
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{sup?.statusnotes}</span>
            </div>
          </div>
          ):("")}
        </div>
      </div> 

<div className='card mb-5 mb-xl-10'   >
            <div className='col-lg-12 card-header cursor-pointer'>
              <div className='col-lg-6 card-title m-0'>
                <h3 className='fw-bolder m-0'>Replies to your support request</h3>
              </div>
             </div>


            <div className='card-body p-9'>
              {replist.length>0 ? ( 
              <div className='row mb-7'>
             
              {replist.map((item: any, i: any) => {
                return(
              <div className="d-flex flex-row justify-content-start mb-4" key={i}>
                <Avatar size="40"  round="20px"
                name={item?.fromName} maxInitials={2} 
                />
                <div className="p-3 me-3 border" style={{borderRadius:"15px"}}>
                  <h5>{item?.fromName}</h5> 
                  <p className="small mb-0">{item?.replymessage}</p>
                </div>
              </div>)
              })}
            </div>):("Reply not found")}
              
            </div>
          </div>


        </div> 
       
      </div>

     
    </>
  )
}
