import {useEffect, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, {Theme} from 'react-select'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getCompany} from '../_requests'
import {creditReport, getClinicCategory} from '../../../clinic-category/role-list/core/_requests'
import React from 'react'

const ClinicReportContent = () => {
  const tableRef = useRef(null)

  const [company, setCompany] = useState<any>([])
  const [category, setCategory] = useState<any>([])

  const [report, setReport] = useState<any>([])

  const [dateRange, setDateRange] = useState([null, null])

  const [OCDate, setOCDate] = useState('')
  const [OCcom, setOCcom] = useState('')
  const [validationError, setValidationError] = useState('')
  const [OCcat, setOCcat] = useState('')
  const [startDate, endDate] = dateRange
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [btnSearchDisable, setbtnSearchDisable] = useState(true)
  const [selectedEmp, setSelectedEmp] = useState('')
  const [OCcomid, setOCcomid] = useState('')
  const [OCcatid, setOCcatid] = useState('')

  const [selectedOption, setSelectedOption] = useState(null)
  const [catselectedOption, setCatSelectedOption] = useState(null)

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    setValidationError('')
    setOCcom(selectedOption?.label)
    setOCcomid(selectedOption?.value)
    getClinicCategory().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAlll = [allOption, ...mappedOptions]

      setCategory(optionsWithAlll)
    })
  }
  const handleCatSelectChange = (catselectedOption: any) => {
    setCatSelectedOption(catselectedOption)

    setOCcat(catselectedOption?.label)
    setOCcatid(catselectedOption?.value)
  }

  useEffect(() => {
    getCompany().then((res: any) => {
      //setCli(res.data.Data)

      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]

      setCompany(optionsWithAll)
    })
  }, [])
  const {mode} = useThemeMode()
  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  const clickReport = () => {
    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    if (Daterange != '' && OCcomid != '') {
      setbtnSearchDisable(false)
      setLoading(true)

      creditReport(OCcomid, Daterange, OCcatid).then((res) => {
        setbtnExcelDisable(false)
        setbtnClearDisable(false)
        setReport(res.Data)
        setLoading(false)
      })
      setOCDate(Daterange)
      setValidationError('')
    } else {
      setbtnSearchDisable(true)
      setValidationError('Please fill manditory fields')
    }
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `CreditBalance_Report_${fileName}`,
    sheet: 'Users',
  })
  const clearDate = () => {
    setValidationError('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
    setOCcom('')
    setOCDate('')
    setReport([])
    setCatSelectedOption(null)
    setSelectedOption(null)
    setDateRange([null, null])
  }
  const lengthsOfUsageArrays = report.map((item: any) => item.usage.length)
  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Agency Report</h3>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Agency</label>

              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={company}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Category</label>

              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={catselectedOption}
                onChange={handleCatSelectChange}
                options={category}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                  setValidationError('')
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clickReport}
                // disabled={btnSearchDisable}
                className='btn text-white col-lg-12'
                style={{background: '#9E2A8D'}}
              >
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                className='btn text-white col-lg-12 ms-2'
                style={{background: '#9E2A8D'}}
                onClick={clearDate}
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                className='btn btn-danger col-lg-12'
                disabled={btnExcelDisable}
                onClick={onDownload}
              >
                Export{' '}
              </button>
            </div>
          </div>
          {validationError ? <div style={{color: 'red'}}>{validationError}</div> : ''}
          <div className='table-responsive'>
            {' '}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-50px' colSpan={5}>
                    {' '}
                    Agency : {OCcom}
                  </th>

                  <th className='min-w-120px' colSpan={4}>
                    {' '}
                    DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={14}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> NO</th>
                  <th className='min-w-140px'> WORKER NAME</th>
                  <th className='min-w-140px'> IC/PASS</th>
                  <th className='min-w-140px'> AGENCY</th>
                  <th className='min-w-120px'> PLAN</th>
                  <th className='min-w-100px'> WBS CODE </th>
                  <th className='min-w-100px'> STATUS </th>
                  <th className='min-w-120px'> CATEGORY</th>
                  <th className='min-w-120px'> LIMIT</th>
                  <th className='min-w-120px'> MONTH</th>

                  <th className='min-w-120px'> USAGE</th>
                  <th className='min-w-120px'> PANEL USAGE</th>
                  <th className='min-w-120px'> NONPANEL USAGE</th>
                  <th className='min-w-120px'> TOTAL</th>
                  <th className='min-w-120px'> BALANCE</th>
                  <th className='min-w-120px'> NO. OF PANEL FORMS</th>
                  <th className='min-w-120px'> NO. OF NON-PANEL FORMS</th>
                  <th className='min-w-120px'> DATE PERIOD</th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={14} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      {item.usage.map((usageItem: any, j: any) => (
                        <tr key={`usage_${index}_${j}`}>
                          {' '}
                          {j === 0 ? (
                            <>
                              {' '}
                              <td>{index + 1}</td>
                              <td>{item.employee.name}</td>
                              <td>{item.employee.nricPassport}</td>
                              <td>{item.employee.client.name}</td>
                              <td>{item.employee.plan.name}</td>
                              <td>{item.employee.wbsCode}</td>
                              <td>{item.employee.suspenseStatus === 2 ? 'Suspended' : 'Active'}</td>
                            </>
                          ) : (
                            <td colSpan={7}></td>
                          )}
                          <td>{usageItem.cliniccategoryname}</td>
                          <td>{usageItem.unlimited === 1 ? 'Unlimited' : usageItem.limit}</td>
                          <td>{usageItem.month}</td>
                          <td>{usageItem.totalAmount}</td>
                          <td>{usageItem.paneltotal}</td>
                          <td>{usageItem.nonpaneltotal}</td>
                          <td>{item?.employee?.sum}</td>
                          <td>
                            {usageItem.currentunlimit === 1
                              ? 'Unlimited'
                              : usageItem.currentbalance}
                          </td>
                          <td>{usageItem.panelcount}</td>
                          <td>{usageItem.nonpanelcount}</td>
                          <td>{OCDate}</td>
                        </tr>
                      ))}{' '}
                    </React.Fragment>
                  ))}
                  {report
                    .map((item: any) => item.usage.length)
                    .every((length: number) => length === 0) ? (
                    <tr>
                      <td colSpan={14} style={{textAlign: 'center'}}>
                        No Data
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>

                // <tbody>
                //   {report.map((item: any, index: any) => (
                //     <React.Fragment key={index}>
                //       <tr>
                //         <td>{index + 1}</td>
                //         <td>{item.employee.name}</td>
                //         <td>{item.employee.nricPassport}</td>
                //         <td>{item.employee.client.name}</td>
                //         <td>{item.employee.plan.name}</td>
                //         <td>{item.employee.wbsCode}</td>
                //         <td>{item.employee.suspenseStatus === 2 ? 'Suspended' : 'Active'}</td>
                //         <td colSpan={6}></td>
                //       </tr>
                //       {item.usage.map((usageItem: any, j: any) => (
                //         <tr key={`usage_${index}_${j}`}>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td>{usageItem.cliniccategoryname}</td>
                //           <td>{usageItem.unlimited === 1 ? 'Unlimited' : usageItem.limit}</td>
                //           <td>{usageItem.month}</td>
                //           <td>{usageItem.totalAmount}</td>
                //           <td></td>
                //           <td>
                //             {usageItem.currentunlimit === 1
                //               ? 'Unlimited'
                //               : usageItem.currentbalance}
                //           </td>
                //           <td>{OCDate}</td>
                //         </tr>
                //       ))}
                //     </React.Fragment>
                //   ))}
                // </tbody>
                // <tbody>
                //   {report?.map((item: any, i: any) => (
                //     <React.Fragment key={i}>
                //       <tr>
                //         {item?.employee && item.usage.length > 0 ? (
                //           <>
                //             <td>{i + 1}</td>
                //             <td>{item?.employee?.name}</td>
                //             <td>{item?.employee?.nricPassport}</td>
                //             <td>{item?.employee?.client?.name}</td>
                //             <td>{item?.employee?.plan?.name}</td>
                //             <td>{item?.employee?.wbsCode}</td>
                //             <td>{item?.employee?.suspenseStatus === 2 ? 'Suspended' : 'Active'}</td>

                //             <td>{item?.usage[0]?.cliniccategoryname}</td>
                //             <td>
                //               {item?.usage[0]?.unlimited === 1
                //                 ? 'Unlimited'
                //                 : item?.usage[0]?.limit}
                //             </td>
                //             <td>{item?.usage[0]?.month}</td>
                //             <td>{item?.usage[0]?.totalAmount}</td>

                //             <td>{item?.employee?.sum}</td>
                //             <td>
                //               {item?.usage[0]?.currentunlimit === 1
                //                 ? 'Unlimited'
                //                 : item?.usage[0]?.currentbalance}
                //             </td>

                //             <td>{OCDate}</td>
                //           </>
                //         ) : null}
                //       </tr>
                //       {item?.usage.map((usageItem: any, j: any) => (
                //         <tr key={`usage_${i}_${j}`}>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td></td>
                //           <td>{usageItem.cliniccategoryname}</td>
                //           <td>{usageItem.unlimited === 1 ? 'Unlimited' : usageItem.limit}</td>
                //           <td>{usageItem.month}</td>
                //           <td>{usageItem.totalAmount}</td>
                //           <td></td>
                //           <td>
                //             {usageItem?.currentunlimit === 1
                //               ? 'Unlimited'
                //               : usageItem?.currentbalance}
                //           </td>
                //           <td></td>
                //         </tr>
                //       ))}
                //     </React.Fragment>
                //   ))}
                //   {report?.length > 0 ? (
                //     ''
                //   ) : (
                //     <tr>
                //       <td colSpan={8} style={{textAlign: 'center'}}>
                //         No Data
                //       </td>
                //     </tr>
                //   )}
                // </tbody>
              )}
              {/* end::Table body */}
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export {ClinicReportContent}
