import {Route, Routes, Outlet, Navigate, Link, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ClientInvoiceWrapper} from './client-invoice-list/ClientInvoiceWrapper'

import {KTCard} from '../../../../../_metronic/helpers'
import Addform from './client-invoice-form/Addform'
import { Viewform } from './client-invoice-form/Viewform'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
 
const cinvoiceBreadcrumbs: Array<PageLink> = [
  {
    title: 'Agency Invoice',
    path: '/portal-agency-invoice',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const ClinicPaymentPage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={cinvoiceBreadcrumbs} >Agency Invoice </PageTitle>
         
              <ClientInvoiceWrapper />
            </>
          }
        />
         
         <Route
          path='/view/:id'
          element={
            <>
              
              <PageTitle  breadcrumbs={cinvoiceBreadcrumbs} >Agency Invoice </PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <Viewform />
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default ClinicPaymentPage
