import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import {AddEmployee, Employee, EmployeeQueryResponse } from './_models'
import { getToken } from '../../../../auth/core/AuthHelpers'
const API_URL = process.env.REACT_APP_API_URLS

const GET_EMPLOYEE_LIST = `${API_URL}/employees/list/query`
const ROLE_URL = `${API_URL}/roleAccess`
const EMPLOYEE_URL = `${API_URL}/employees/add`
const EMPLOYEE_VIEW = `${API_URL}/employees/viewEmployee`
const EMPLOYEE_UPDATE = `${API_URL}/employees/updateEmployee`
const EMPLOYEE_DELETE = `${API_URL}/employees/deleteEmployee`

var AuthVerify = getToken.call(this);

const listEmployee = (query:any,type:any,typeId:any): Promise<EmployeeQueryResponse> => {
  return axios
    .put(`${GET_EMPLOYEE_LIST}?${query}`,{type,typeId}, getToken())
    .then((d: AxiosResponse<EmployeeQueryResponse>) => {
      return d.data
    })
}

const getRole= async (clientId:any) => {
  const res = await axios.get(`${ROLE_URL}/getClientRole/${clientId}`, getToken())
  return res;
} 

const createEmployee = (employee: AddEmployee): Promise<Employee | undefined> => {
  return axios
    .post(EMPLOYEE_URL, employee,getToken())
    .then((response) => {
      return response.data
     })
   
}

const deleteEmployee = (id: any) => {
  return axios
    .delete(`${EMPLOYEE_DELETE}/${id}`,getToken())
    .then((response) => {
      
   return response.data;

    }
    )
}

const viewEmployee = (id:any) => {
  return axios
    .get(`${EMPLOYEE_VIEW}/${id}`,getToken()) 
    .then((response) =>{
 
      return response.data;
    }
   )
}

const updateEmployee = (data: any,id:any) => {
  return axios
    .post(`${EMPLOYEE_UPDATE}/${id}`, data,getToken())
    .then((response) => {
    
      return response.data;
    })
}
export {
  listEmployee,
  deleteEmployee,
  viewEmployee,
  createEmployee,
  getRole,updateEmployee
}
